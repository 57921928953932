import React, { useEffect } from "react";

// styles import
import styles from "../Users.module.scss";

// components import
import ResponderSubmitterProviderHeader from "./ResponderSubmitterProviderHeader";
import ResponderSubmitterProviderTable from "./ResponderSubmitterProviderTable";
import ResponderSubmitterProviderPagination from "./ResponderSubmitterProviderPagination";
import ResponderSubmitterProviderMobileRows from "./ResponderSubmitterProviderMobileRows";

// store import
import { useDispatch } from "react-redux";
import {
  changeResponderSubmitterProviderData,
  resetResponderSubmitterProviderPayload,
} from "../../../store/ResponderSubmitterProviderSlice";

// constants import
import {
  DummyTableData,
  constantResponderSubmitterProviderPayload,
} from "../../../utils/ConstantObjects";

export default function ResponderSubmitterProvider() {
  const isMobile = window.innerWidth < 768; // threshold for mobile devices

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(changeResponderSubmitterProviderData(DummyTableData));
      dispatch(resetResponderSubmitterProviderPayload(constantResponderSubmitterProviderPayload));
    };
  }, []);

  return (
    <>
      <ResponderSubmitterProviderHeader />
      {!isMobile && (
        <div className={styles["users-table-visibility"]}>
          <ResponderSubmitterProviderTable />
        </div>
      )}
      {isMobile && (
        <div className={styles["users-card-visibility"]}>
          <ResponderSubmitterProviderMobileRows />
        </div>
      )}
      {!isMobile && <ResponderSubmitterProviderPagination />}
    </>
  );
}
