import { React } from "react";
import { Button, Drawer, Input, Skeleton, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// store import
import {
  changeSubmitterConsumerFilterAppliedStatus,
  changeSubmitterConsumerFilterCount,
  changeFilterDrawerVisibility,
  resetSubmitterConsumerPayloadOnCancelAndFilterNotApplied,
  changeFilterSubmitterConsumerName,
  changeFilterMemberID,
  changeSubmitterConsumerIsError,
  changeSubmitterConsumerPageNo,
  resetPayloadFilter,
} from "../../../store/SubmitterConsumerSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// styles import
import styles from "../Users.module.scss";

// constants import
import languageConstant from "../../../resources/constants/StringConstants";
// import { OK } from "../../resources/constants/ResponseCode";
import { constantSubmitterConsumerPayload } from "../../../utils/ConstantObjects";

// function import
import { FetchSubmitterConsumer } from "./FetchSubmitterConsumer";

// caching hook import
// import { useQuery } from "react-query";

// component import
// import PlainSomethingWentWrong from "../../errorPage/PlainSomethingWentWrong";

// helper function
import { appliedSubmitterConsumerFiltersCount } from "../../../utils/helperFunctions";

export default function SubmitterConsumerFilterDrawer() {
  const { langKey, payload, apiLoading, filterApplied } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      payload: store.submitterConsumer.payload,
      apiLoading: store.submitterConsumer.isLoading,
      filterApplied: store.submitterConsumer.filterDrawer.filterApplied,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(changeFilterDrawerVisibility(false));
    if (!filterApplied) {
      dispatch(resetSubmitterConsumerPayloadOnCancelAndFilterNotApplied());
    } else if (filterApplied && appliedSubmitterConsumerFiltersCount(payload) === 0) {
      dispatch(changeSubmitterConsumerFilterCount(0));
      dispatch(changeSubmitterConsumerFilterAppliedStatus(false));
      submitterConsumerFetchFuncton(constantSubmitterConsumerPayload);
      dispatch(resetPayloadFilter(constantSubmitterConsumerPayload));
    }
  };

  const submitterConsumerFetchFuncton = async (constantSubmitterConsumerBody) => {
    const isErrorFlag = await FetchSubmitterConsumer(
      dispatch,
      constantSubmitterConsumerBody ? constantSubmitterConsumerBody : payload
    );
    dispatch(changeSubmitterConsumerIsError(isErrorFlag));
  };

  return (
    <Drawer
      title="Filter By"
      placement="right"
      closable={true}
      width={300}
      onClose={handleCancel}
      open={true}
    >
      <div className={styles["users-drawer-main-container"]}>
        <div className={styles["users-drawer-filter-div"]}>
          {/* {sessionStorage.getItem("role") === "Super Admin" && (
            <div className={styles["users-drawer-item-gap"]}>
              <Typography className={styles["users-filter-drawer-heading"]}>
                {languageConstant[langKey].organization}
              </Typography>
              {apiLoading? (
                <div className={styles["users-drawer-elements-container"]}>
                  <div className={styles["users-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["users-drawer-elements-container"]}>
                  <Select
                    className={styles["users-drawer-select"]}
                    variant="filled"
                    showSearch
                    optionFilterProp="children"
                    placeholder={languageConstant[langKey].selectOrganization}
                    value={payload.org_array}
                    onChange={(e) => {
                      dispatch(changeFilterOrgIds(e));
                    }}
                    mode="multiple"
                  >
                    {orgList
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((item) => (
                        <Select.Option key={item.org_id} value={item.org_id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              )}
            </div>
          )}  */}
          <div className={styles["users-drawer-item-gap"]}>
            <Typography className={styles["users-filter-drawer-heading"]}>
              {languageConstant[langKey].search}
            </Typography>
            {apiLoading ? (
              <div className={styles["users-drawer-elements-container"]}>
                <div className={styles["users-drawer-shimmer-div"]}>
                  <Skeleton.Input
                    active
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles["users-drawer-elements-container"]}>
                <Input
                  variant="filled"
                  placeholder={languageConstant[langKey].searchUsingSubmitterConsumerName}
                  className={styles["users-drawer-search"]}
                  prefix={<SearchOutlined />}
                  value={payload.patient_name}
                  onChange={(e) => {
                    dispatch(changeFilterSubmitterConsumerName(e.target.value));
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles["users-drawer-item-gap"]}>
            {apiLoading ? (
              <div className={styles["users-drawer-elements-container"]}>
                <div className={styles["users-drawer-shimmer-div"]}>
                  <Skeleton.Input
                    active
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles["users-drawer-elements-container"]}>
                <Input
                  variant="filled"
                  placeholder={languageConstant[langKey].searchUsingMemberID}
                  className={styles["users-drawer-search"]}
                  prefix={<SearchOutlined />}
                  value={payload.memberID}
                  onChange={(e) => {
                    dispatch(changeFilterMemberID(e.target.value));
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles["users-drawer-buttons-div"]}>
          <Button
            type="primary"
            className="general-button-extra-large"
            disabled={apiLoading}
            onClick={() => {
              dispatch(changeSubmitterConsumerFilterAppliedStatus(true));
              dispatch(changeSubmitterConsumerPageNo(1));
              submitterConsumerFetchFuncton();
            }}
            loading={apiLoading}
          >
            {languageConstant[langKey].filter}
          </Button>
          <Button
            type="default"
            className="general-button-extra-large-secondary"
            disabled={apiLoading}
            onClick={() => {
              dispatch(resetPayloadFilter(constantSubmitterConsumerPayload));
              dispatch(changeSubmitterConsumerFilterAppliedStatus(false));
              submitterConsumerFetchFuncton(constantSubmitterConsumerPayload);
              handleCancel();
            }}
          >
            {languageConstant[langKey].clear}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
