import { createSlice } from "@reduxjs/toolkit";

// constants import
import { DummyTableData, constantOrganizationPayload } from "../utils/ConstantObjects";

const OrganizationSlice = createSlice({
  name: "organization",
  initialState: {
    isLoading: true,
    isError: false,
    infiniteScrollLoading: false,
    organizationData: DummyTableData,
    totalRecords: 0,
    payload: constantOrganizationPayload,
  },
  reducers: {
    changeOrganizationIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeOrganizationIsError: (state, action) => {
      state.isError = action.payload;
    },
    changeOrganizationInfiniteScrollLoading: (state, action) => {
      state.infiniteScrollLoading = action.payload;
    },
    changeOrganizationData: (state, action) => {
      state.organizationData = action.payload;
    },
    changeOrganizationMobileData: (state, action) => {
      const payload = action.payload || {};

      const neworganizationData = [...state.organizationData, ...payload];

      state.organizationData = neworganizationData;
    },
    changeOrganizationTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    changeOrganizationPageNo: (state, action) => {
      state.payload.page = action.payload;
    },
    changeOrganizationPageLimit: (state, action) => {
      state.payload.limit = action.payload;
    },
    resetOrganizationPayload: (state, action) => {
      state.payload = action.payload;
    },
  },
});

export const {
  changeOrganizationIsLoading,
  changeOrganizationIsError,
  changeOrganizationInfiniteScrollLoading,
  changeOrganizationData,
  changeOrganizationMobileData,
  changeOrganizationTotalRecords,
  changeOrganizationPageNo,
  changeOrganizationPageLimit,
  resetOrganizationPayload,
} = OrganizationSlice.actions;

export default OrganizationSlice.reducer;
