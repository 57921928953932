import { Modal, Select } from "antd";
import React from "react";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeCurrentSelectedUserModal,
  changeUsersAddModalVisibility,
  changeUsersEditModalVisibility,
} from "../../store/UsersSlice";

// constants import
import { constantUsersList } from "../../utils/ConstantObjects";

// styles import
import styles from "./Users.module.scss";

// components import
/* eslint-disable max-len */
import ResponderSubmitterProviderModal from "./responderAndSubmitterProvider/modal/ResponderSubmitterProviderModal";
/* eslint-enable max-len */
import SubmitterModal from "./submitter/modal/SubmitterModal";

export default function UsersModal() {
  const { addModalVisible, editModalVisible, selectedUserModal } = useSelector(
    (store) => ({
      addModalVisible: store.users.addModalVisible,
      editModalVisible: store.users.editModalVisible,
      selectedUserModal: store.users.currentUserModalSelected,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const getUserList = () => {
    return (
      constantUsersList.find((user) => user?.value === selectedUserModal)?.label ||
      languageConstant[langKey].user
    );
  };

  const handleCancel = () => {
    if (addModalVisible) {
      dispatch(changeUsersAddModalVisibility(false));
    } else {
      dispatch(changeUsersEditModalVisibility(false));
    }
  };

  return (
    <Modal
      title={
        <div className={styles["users-addModal-header"]}>
          <div>{`${addModalVisible ? "Add" : "Edit"} ${getUserList()}`}</div>
          {addModalVisible && (
            <Select
              className={styles["users-select-add-modal"]}
              variant="filled"
              showSearch={false}
              value={selectedUserModal}
              onChange={(value) => {
                dispatch(changeCurrentSelectedUserModal(value));
              }}
              options={constantUsersList}
              mode="single"
            ></Select>
          )}
        </div>
      }
      centered
      open={addModalVisible || editModalVisible}
      footer={null}
      width="90%"
      closable={false}
      onCancel={handleCancel}
    >
      <hr />
      {selectedUserModal === constantUsersList[0]?.value &&
       sessionStorage.getItem("role") == "Super Admin" && <ResponderSubmitterProviderModal />}
      {selectedUserModal === constantUsersList[0]?.value &&
       sessionStorage.getItem("role") !== "Super Admin" && <SubmitterModal />}
    </Modal>
  );
}
