import React, { useEffect } from "react";
import { Avatar, Skeleton, Table, Tooltip } from "antd";

// styles import
import styles from "../Users.module.scss";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeResponderSubmitterProviderIsError,
  updateResponderSubmitterProviderModalDto,
} from "../../../store/ResponderSubmitterProviderSlice"; // eslint-disable-line max-len
import {
  changeCurrentSelectedUserModal,
  changeUsersEditModalVisibility,
} from "../../../store/UsersSlice";

// constants import
import editIcon from "../../../resources/assets/edit.svg";
import defaultImg from "../../../resources/assets/defaultImg.svg";
import languageConstant from "../../../resources/constants/StringConstants";
import { constantUsersList } from "../../../utils/ConstantObjects";

// component import
import SomethingWentWrong from "../../errorPage/SomethingWentWrong";

// api function import
import { FetchResponderSubmitterProvider } from "./FetchResponderSubmitterProvider";

export default function ResponderSubmitterProviderTable() {
  const { langKey, payload, isLoading, userData, isError } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      payload: store.responderSubmitterProvider.payload,
      isLoading: store.responderSubmitterProvider.isLoading,
      userData: store.responderSubmitterProvider.userData,
      isError: store.responderSubmitterProvider.isError,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const fetchResponderSubmitterProviderFunction = async () => {
    const isErrorFlag = await FetchResponderSubmitterProvider(dispatch, payload, null, false);
    dispatch(changeResponderSubmitterProviderIsError(isErrorFlag));
  };
  useEffect(() => {
    fetchResponderSubmitterProviderFunction();
  }, []);

  const handleEditButton = () => {
    dispatch(changeCurrentSelectedUserModal(constantUsersList[0]?.value));
    dispatch(
      updateResponderSubmitterProviderModalDto({
        newName: "danny",
        newAge: 8,
      })
    );
    dispatch(changeUsersEditModalVisibility(true));
  };

  const columns = [
    {
      title: languageConstant[langKey].userName,
      render: (record) =>
        isLoading ? (
          <div className={styles["user-pic-info-div"]}>
            {/* add size of the image used below */}
            <Skeleton.Avatar active size={34} />
            <span style={{ marginTop: "auto" }}>
              <Skeleton.Input style={{ height: 12 }} active avatar />
            </span>
          </div>
        ) : (
          <div className={styles["user-pic-info-div"]}>
            <Avatar
              /* add size of the image used below */
              size={34}
              icon={
                <img
                  className={styles["users-profile-pic"]}
                  alt="Profile Pic"
                  src={record["image_url"] || defaultImg}
                />
              }
            />
            <span>
              {`${record["first_name"]} ${record["last_name"]}`.trim() ||
                languageConstant[langKey].noData}
            </span>
          </div>
        ),
    },
    {
      title: languageConstant[langKey].speciality,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["spec_master.name"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].organization,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["org_dtl"]?.[0]?.["organization.name"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].clinicOrg,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["hospital_name"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].mobileNo,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["mobile"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].email,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["email"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].actions,
      render: () =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          <Tooltip title={languageConstant[langKey].editResponderSubmitterProvider}>
            <img
              src={editIcon}
              alt="ai"
              className={styles["users-action-img"]}
              onClick={() => {
                handleEditButton();
              }}
            />
          </Tooltip>
        ),
    },
  ];

  if (isError) return <SomethingWentWrong refetch={fetchResponderSubmitterProviderFunction} />;

  return (
    <div className={styles["users-table-container"]}>
      <Table
        dataSource={userData}
        rowKey={(record) => record["user_id"]}
        columns={columns}
        pagination={false}
        style={{ whiteSpace: "nowrap" }}
      />
    </div>
  );
}
