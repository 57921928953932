import React, { useState } from "react";
import { Button, Form, Input, Typography } from "antd";

//image import
import afyaLogo from "../../resources/assets/afya-icon.svg";

//styles import
import styles from "./Login.module.scss";

//constants import
import languageConstant from "../../resources/constants/StringConstants";
import RegExPatternConstants from "../../resources/constants/RegExPatternConstants";
import { PATH_INSIGHTS } from "../../resources/constants/RoutesPathConstants";

//funtion import
import { LoginLogic } from "./LoginLogic";

// store import
import { useSelector } from "react-redux";

export default function LoginFields() {
  const langKey = useSelector((store) => store.config.lang);
  const [loading, setLoading] = useState(false);
  const onFinish = (values) => {
    // Here you can add your login logic
    doLogin(values);
  };

  const doLogin = async (values) => {
    setLoading(true);
    /* eslint-disable camelcase */
    const dto = {
      email: values.email,
      password: values.password,
      force_login: 0,
      scope: "admin",
    };
    /* eslint-enable camelcase */
    const status = await LoginLogic(dto); // await here
    if (status) {
      window.location.pathname = PATH_INSIGHTS;
      setLoading(false);
    } else {
      setLoading(false);
    }
  };

  return (
    <div className={styles["login-main-container-fields-div"]}>
      <img src={afyaLogo} alt="" className={styles["login-afya-logo-size"]} />
      <Typography className={styles["login-main-container-fields-div-heading-1"]}>
        {languageConstant[langKey].loginFieldsHeading1}
      </Typography>
      <Typography className={styles["login-main-container-fields-div-heading-2"]}>
        {languageConstant[langKey].loginFieldsHeading2}
      </Typography>
      <Form
        name="loginForm"
        onFinish={onFinish}
        className={styles["login-main-container-fields-div-login-form"]}
      >
        <Form.Item
          name="email"
          className={styles["login-main-container-fields-div-form-item"]}
          rules={[
            { required: true, message: languageConstant[langKey].emailRequiredText },
            {
              pattern: RegExPatternConstants.emailPattern,
              message: languageConstant[langKey].emailPatternText,
            },
          ]}
        >
          <Input
            placeholder={languageConstant[langKey].emailPlaceholder}
            variant="filled"
            className={styles["login-main-container-fields-div-email"]}
          />
        </Form.Item>
        <Form.Item
          name="password"
          className={styles["login-main-container-fields-div-form-item"]}
          rules={[{ required: true, message: languageConstant[langKey].passwordRequiredText }]}
        >
          <Input.Password
            placeholder={languageConstant[langKey].passwordPlaceholder}
            variant="filled"
            className={styles["login-main-container-fields-div-password"]}
            autoComplete="off"
          />
        </Form.Item>
        <Typography className={styles["login-main-container-fields-div-forgot-password"]}>
          {languageConstant[langKey].ForgotPasswordText}
        </Typography>
        <Form.Item className={styles["login-main-container-fields-div-form-item-button"]}>
          <Button
            type="primary"
            htmlType="submit"
            className={styles["login-main-container-fields-div-sign-in-button"]}
            loading={loading}
          >
            {languageConstant[langKey].LoginSignInText}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
}
