import { Button, Typography } from "antd";
import React from "react";
import PropTypes from "prop-types";

// react selector
import { useSelector } from "react-redux";

// constant import
import languageConstant from "../../resources/constants/StringConstants";

export default function PlainSomethingWentWrong({ refetch }) {
  const langKey = useSelector((store) => store.config.lang);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        gap: "10px",
        height: "100%",
      }}
    >
      <Typography>{languageConstant[langKey].serverErrorSomethingWentWrong}</Typography>
      <Button type="primary" className="general-button" onClick={refetch}>
        {languageConstant[langKey].tryAgain}
      </Button>
    </div>
  );
}
PlainSomethingWentWrong.propTypes = {
  refetch: PropTypes.func,
};
