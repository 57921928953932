import React, { useEffect, useState } from "react";
import { Button, Modal, Select, Skeleton, message } from "antd";

// store import
import { changeReassignModalVisibility } from "../../store/ChatSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// constants import
import languageConstant from "../../resources/constants/StringConstants";
import { OK } from "../../resources/constants/ResponseCode";
import { DATATYPES } from "../../utils/ConstantObjects";

// api import
import {
  addChatData,
  // manuallyAssignUser,
  manuallyAssignPCP,
  pcpAutoAssign,
  //userAutoAssign,
} from "../../services/apiServices/ChatsAPI";

// styles import
import styles from "./Chats.module.scss";

// component import
import PlainSomethingWentWrong from "../errorPage/PlainSomethingWentWrong";

export default function ReassignModal() {
  const { visible, langKey, payload } = useSelector(
    (store) => ({
      visible: store.chats.reassignModal.visible,
      payload: store.chats.reassignModal.payload,
      // payloadPcp: store.responderSubmitterProvider.payload,
      langKey: store.config.lang,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(changeReassignModalVisibility(false));
  };
  const [loading, setLoading] = useState(true);
  const [localAutoAssignUsers, setLocalAutoAssignUsers] = useState([]);
  const [selectedProviderIdAndAgoraId, setSelectedProviderIdAndAgoraId] = useState({});
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    autoAassignFunction();
  }, []);

  const autoAassignFunction = async () => {
    const isErrorFlag = await autoAssign();
    setIsError(isErrorFlag);
  };

  const autoAssign = async () => {
    try {
      setLoading(true);
      //const data = await userAutoAssign(payload);
      const data = await pcpAutoAssign(payload);
      if (data?.status === OK) {
        setLocalAutoAssignUsers(data?.data || []);
      }

      return !(data?.status === OK); //to satisfy isError flag
    } catch (error) {
      console.log(error);
      setLocalAutoAssignUsers([]);

      return true; // to satisfy isError flag
      // empty catch block since error is already handled in interceptors
    } finally {
      setLoading(false);
    }
  };

  const manualReassignUser = async () => {
    try {
      if (!selectedProviderIdAndAgoraId["data-user_id"]) {
        message.error({
          content: languageConstant[langKey].pleaseChooseAProvider,
          style: {
            marginTop: "12vh",
          },
        });

        return;
      }
      setLoading(true);
      /* eslint-disable camelcase */
      const body = {
        agora_id: selectedProviderIdAndAgoraId["data-agora_id"],
        submitter_id: selectedProviderIdAndAgoraId["data-user_id"],
        session_id: payload.session_id,
        speciality: payload.specialty,
        role: sessionStorage.getItem("role"),
        authToken: sessionStorage.getItem("authToken"),
      };
      console.log(body);
      // /* eslint-enable camelcase */
      const data = await manuallyAssignPCP(body);
      if (data?.status === OK) {
        const selectedProviderName = `${data?.data["owner1.first_name"]} 
        ${data?.data["owner1.last_name"]}`;
        const bannerStatus = await addChatBanner(selectedProviderName);
        if (bannerStatus) {
          handleCancel();
          message.success({
            content: languageConstant[langKey].successfullyReassigned,
            style: {
              marginTop: "12vh",
            },
          });
          setSelectedProviderIdAndAgoraId({});
        } else {
          message.error({
            content: languageConstant[langKey].userReassignedButFailedToAddBanner,
            style: {
              marginTop: "12vh",
            },
          });
        }
      } else {
        message.error({
          content: languageConstant[langKey].failedToReassign,
          style: {
            marginTop: "12vh",
          },
        });
      }
    } catch (error) {
      // empty catch block since error is already handled in interceptors
    } finally {
      setLoading(false);
    }
  };

  const addChatBanner = async (selectedProviderName) => {
    try {
      const fromName = `${sessionStorage.getItem("firstName")} 
      ${sessionStorage.getItem("lastName")}`;
      /* eslint-disable camelcase */
      const localPayload = {
        speciality: payload.specialty,
        from_id: sessionStorage.getItem("userId"),
        session_id: payload.session_id,
        from_name: fromName,
        datatype: DATATYPES.notes,
        message: `${fromName} ${languageConstant[langKey].hadAddedDoctor} 
        ${selectedProviderName} ${languageConstant[langKey].toTheChat}`,
        link: "",
        img_url: "",
      };
      /* eslint-enable camelcase */

      const data = await addChatData(localPayload);

      return data?.status === OK;
    } catch (error) {
      return false;
      // empty catch block since error is already handled in interceptors
    }
  };

  return (
    <Modal
      title={languageConstant[langKey].reassignChats}
      centered
      open={visible}
      footer={
        !isError && (
          <div className="global-footer-container">
            <Button type="primary" className="general-button-large" onClick={handleCancel}>
              {languageConstant[langKey].cancel}
            </Button>
            <Button
              type="primary"
              className="general-button-large"
              disabled={loading}
              onClick={() => {
                manualReassignUser();
              }}
            >
              {languageConstant[langKey].save}
            </Button>
          </div>
        )
      }
      width={800}
      onCancel={handleCancel}
    >
      <hr />
      {loading ? (
        <div className={styles["chats-reassign-modal-container"]}>
          <div className={styles["chats-reassign-shimmer-container"]}>
            <Skeleton.Input
              active
              style={{
                position: "absolute",
                width: "100%",
                height: "40px",
              }}
            />
          </div>
        </div>
      ) : isError ? (
        <PlainSomethingWentWrong refetch={autoAassignFunction} />
      ) : (
        <div className={styles["chats-reassign-modal-container"]}>
          <Select
            className={styles["chats-reassign-select"]}
            variant="filled"
            showSearch
            optionFilterProp="children"
            placeholder={languageConstant[langKey].selectProvider}
            onChange={(value, option) => {
              setSelectedProviderIdAndAgoraId(option);
            }}
          >
            {localAutoAssignUsers
              .sort((a, b) => a.first_name.localeCompare(b.first_name))
              .map((item) => (
                <Select.Option
                  key={item.user_id}
                  value={item.user_id}
                  data-user_id={item.user_id}
                  data-agora_id={item.agora_id}
                >
                  {/* eslint-disable */}
                  {item.first_name + " " + item.last_name}
                </Select.Option>
              ))}
          </Select>
        </div>
      )}
    </Modal>
  );
}
