import { createSlice } from "@reduxjs/toolkit";

// constants import
import {
  DummyTableData,
  constantResponderSubmitterProviderPayload,
} from "../utils/ConstantObjects";

const ResponderSubmitterProviderSlice = createSlice({
  name: "responderSubmitterProvider",
  initialState: {
    isLoading: true,
    isError: false,
    infiniteScrollLoading: false,
    userData: DummyTableData,
    totalRecords: 0,
    payload: constantResponderSubmitterProviderPayload,
    modalDto: {
      name: "",
      age: 21,
    },
  },
  reducers: {
    changeResponderSubmitterProviderIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeResponderSubmitterProviderIsError: (state, action) => {
      state.isError = action.payload;
    },
    changeResponderSubmitterProviderInfiniteScrollLoading: (state, action) => {
      state.infiniteScrollLoading = action.payload;
    },
    changeResponderSubmitterProviderData: (state, action) => {
      state.userData = action.payload;
    },
    changeResponderSubmitterProviderMobileData: (state, action) => {
      const payload = action.payload || {};

      const newUserData = [...state.userData, ...payload];

      state.userData = newUserData;
    },
    changeResponderSubmitterProviderTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    changeResponderSubmitterProviderPageNo: (state, action) => {
      state.payload.page = action.payload;
    },
    changeResponderSubmitterProviderPageLimit: (state, action) => {
      state.payload.limit = action.payload;
    },
    resetResponderSubmitterProviderPayload: (state, action) => {
      state.payload = action.payload;
    },
    updateResponderSubmitterProviderModalDto: (state, action) => {
      const { newName, newAge } = action.payload;
      state.modalDto = {
        name: newName,
        age: newAge,
      };
    },
  },
});

export const {
  changeResponderSubmitterProviderIsLoading,
  changeResponderSubmitterProviderIsError,
  changeResponderSubmitterProviderInfiniteScrollLoading,
  changeResponderSubmitterProviderData,
  changeResponderSubmitterProviderMobileData,
  changeResponderSubmitterProviderTotalRecords,
  changeResponderSubmitterProviderPageNo,
  changeResponderSubmitterProviderPageLimit,
  resetResponderSubmitterProviderPayload,
  updateResponderSubmitterProviderModalDto,
} = ResponderSubmitterProviderSlice.actions;

export default ResponderSubmitterProviderSlice.reducer;
