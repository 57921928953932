import React from "react";
import { Button, DatePicker, Drawer, Input, Select, Skeleton, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// store import
import {
  changeChatsFilterAppliedStatus,
  changeChatsFilterCount,
  changeFilterChatId,
  changeFilterConsumerName,
  changeFilterCreatedDate,
  changeFilterDrawerVisibility,
  changeFilterOrgIds,
  changeFilterResponderName,
  changeFilterSpecIds,
  changeFilterSubmiterName,
  changeFilterUpdatedDate,
  changeIsErrorStatus,
  changePageNumber,
  resetChatsPayloadFilter,
  resetChatsPayloadOnCancelAndFilterNotApplied,
} from "../../store/ChatSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// styles import
import styles from "./Chats.module.scss";

// constants import
import languageConstant from "../../resources/constants/StringConstants";
import { OK } from "../../resources/constants/ResponseCode";
import { ConstantPickerDateFormat, constantChatPayload } from "../../utils/ConstantObjects";

//api import
import { getOrgList, getSpecialityList } from "../../services/apiServices/ChatsAPI";

// function import
import { ChatsFetch } from "./ChatsFetch";

// date parser
import { DateTime } from "luxon";
import dayjs from "dayjs";

// caching hook import
import { useQuery } from "react-query";

// component import
import PlainSomethingWentWrong from "../errorPage/PlainSomethingWentWrong";

// helpter function
import { appliedChatFiltersCount } from "../../utils/helperFunctions";

export default function FilterDrawer() {
  const { langKey, payload, apiLoading, filterApplied } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      payload: store.chats.chatsPayload,
      apiLoading: store.chats.isLoading,
      filterApplied: store.chats.filterDrawer.filterApplied,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(changeFilterDrawerVisibility(false));
    if (!filterApplied) {
      dispatch(resetChatsPayloadOnCancelAndFilterNotApplied());
    } else if (filterApplied && appliedChatFiltersCount(payload) === 0) {
      dispatch(changeChatsFilterCount(0));
      dispatch(changeChatsFilterAppliedStatus(false));
      chatsFetchFuncton(constantChatPayload);
      dispatch(resetChatsPayloadFilter(constantChatPayload));
    }
  };

  const fetchOrgList = async () => {
    try {
      const response = await getOrgList();
      if (response?.status === OK) {
        return response?.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const fetchSpecList = async () => {
    try {
      const payload = {
        dob: "",
      };
      const response = await getSpecialityList(payload);
      if (response?.status === OK) {
        return response?.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const {
    data: orgList,
    isLoading: orgLoading,
    isError: orgError,
    refetch: orgRefetch,
  } = useQuery("chatsOrgQuery", fetchOrgList, {
    retry: false,
  });

  const {
    data: specList,
    isLoading: specLoading,
    isError: specError,
    refetch: specRefetch,
  } = useQuery("chatsSpecQuery", fetchSpecList, {
    retry: false,
  });

  const chatsFetchFuncton = async (constantChatBody) => {
    const isErrorFlag = await ChatsFetch(dispatch, constantChatBody ? constantChatBody : payload);
    dispatch(changeIsErrorStatus(isErrorFlag));
  };

  const disableFutureDates = (current) => {
    // Can not select days after today
    return current && current > DateTime.now().endOf("day");
  };

  return (
    <Drawer
      title="Filter By"
      placement="right"
      closable={true}
      width={300}
      onClose={handleCancel}
      open={true}
    >
      <div className={styles["chats-drawer-main-container"]}>
        {orgError || specError ? (
          <PlainSomethingWentWrong refetch={orgError ? orgRefetch : specRefetch} />
        ) : (
          <div className={styles["chats-drawer-filter-div"]}>
            {sessionStorage.getItem("role") === "Super Admin" && (
              <div className={styles["chats-drawer-item-gap"]}>
                <Typography className={styles["chats-filter-drawer-heading"]}>
                  {languageConstant[langKey].organization}
                </Typography>
                {orgLoading || specLoading ? (
                  <div className={styles["chats-drawer-elements-container"]}>
                    <div className={styles["chats-drawer-shimmer-div"]}>
                      <Skeleton.Input
                        active
                        style={{
                          position: "absolute",
                          width: "100%",
                          height: "40px",
                        }}
                      />
                    </div>
                  </div>
                ) : (
                  <div className={styles["chats-drawer-elements-container"]}>
                    <Select
                      className={styles["chats-drawer-select"]}
                      variant="filled"
                      showSearch
                      optionFilterProp="children"
                      placeholder={languageConstant[langKey].selectOrganization}
                      value={payload.org_array}
                      onChange={(e) => {
                        dispatch(changeFilterOrgIds(e));
                      }}
                      mode="multiple"
                    >
                      {orgList
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((item) => (
                          <Select.Option key={item.org_id} value={item.org_id}>
                            {item.name}
                          </Select.Option>
                        ))}
                    </Select>
                  </div>
                )}
              </div>
            )}
            <div className={styles["chats-drawer-item-gap"]}>
              <Typography className={styles["chats-filter-drawer-heading"]}>
                {languageConstant[langKey].speciality}
              </Typography>
              {orgLoading || specLoading ? (
                <div className={styles["chats-drawer-elements-container"]}>
                  <div className={styles["chats-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["chats-drawer-elements-container"]}>
                  <Select
                    className={styles["chats-drawer-select"]}
                    variant="filled"
                    showSearch
                    optionFilterProp="children"
                    placeholder={languageConstant[langKey].selectSpeciality}
                    value={payload.spec_array}
                    onChange={(e) => {
                      dispatch(changeFilterSpecIds(e));
                    }}
                    mode="multiple"
                  >
                    {specList
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((item) => (
                        <Select.Option key={item.spec_id} value={item.spec_id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              )}
            </div>
            <div className={styles["chats-drawer-item-gap"]}>
              <Typography className={styles["chats-filter-drawer-heading"]}>
                {languageConstant[langKey].search}
              </Typography>
              {orgLoading || specLoading ? (
                <div className={styles["chats-drawer-elements-container"]}>
                  <div className={styles["chats-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["chats-drawer-elements-container"]}>
                  <Input
                    variant="filled"
                    placeholder={languageConstant[langKey].searchUsingChatId}
                    className={styles["chats-drawer-search"]}
                    value={payload.chat_id}
                    prefix={<SearchOutlined />}
                    onChange={(e) => {
                      dispatch(changeFilterChatId(e.target.value.trim()));
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles["chats-drawer-item-gap"]}>
              {orgLoading || specLoading ? (
                <div className={styles["chats-drawer-elements-container"]}>
                  <div className={styles["chats-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["chats-drawer-elements-container"]}>
                  <Input
                    variant="filled"
                    placeholder={languageConstant[langKey].searchUsingSubmiterName}
                    className={styles["chats-drawer-search"]}
                    prefix={<SearchOutlined />}
                    value={payload.sub_name}
                    onChange={(e) => {
                      dispatch(changeFilterSubmiterName(e.target.value));
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles["chats-drawer-item-gap"]}>
              {orgLoading || specLoading ? (
                <div className={styles["chats-drawer-elements-container"]}>
                  <div className={styles["chats-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["chats-drawer-elements-container"]}>
                  <Input
                    variant="filled"
                    placeholder={languageConstant[langKey].searchUsingResponderName}
                    className={styles["chats-drawer-search"]}
                    prefix={<SearchOutlined />}
                    value={payload.res_name}
                    onChange={(e) => {
                      dispatch(changeFilterResponderName(e.target.value));
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles["chats-drawer-item-gap"]}>
              {orgLoading || specLoading ? (
                <div className={styles["chats-drawer-elements-container"]}>
                  <div className={styles["chats-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["chats-drawer-elements-container"]}>
                  <Input
                    variant="filled"
                    placeholder={languageConstant[langKey].searchUsingConsumerName}
                    className={styles["chats-drawer-search"]}
                    prefix={<SearchOutlined />}
                    value={payload.patient_name}
                    onChange={(e) => {
                      dispatch(changeFilterConsumerName(e.target.value));
                    }}
                  />
                </div>
              )}
            </div>
            <div className={styles["chats-drawer-item-gap"]}>
              <Typography className={styles["chats-filter-drawer-heading"]}>
                {languageConstant[langKey].date}
              </Typography>
              {orgLoading || specLoading ? (
                <div className={styles["chats-drawer-elements-container"]}>
                  <div className={styles["chats-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["chats-drawer-elements-container"]}>
                  <DatePicker
                    variant="filled"
                    format={ConstantPickerDateFormat}
                    className={styles["chats-drawer-date"]}
                    placeholder={languageConstant[langKey].createdDate}
                    defaultValue={payload.crt_dt ? dayjs(payload?.crt_dt, "MM-DD-YYYY") : null}
                    onChange={(date, datestring) => {
                      dispatch(
                        changeFilterCreatedDate(
                          // luxon format MM -- month_dt
                          datestring
                            ? DateTime.fromFormat(datestring, "dd-MM-yyyy").toFormat("MM-dd-yyyy")
                            : ""
                        )
                      );
                    }}
                    disabledDate={disableFutureDates}
                  />
                </div>
              )}
            </div>
            <div className={styles["chats-drawer-item-gap"]}>
              {orgLoading || specLoading ? (
                <div className={styles["chats-drawer-elements-container"]}>
                  <div className={styles["chats-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["chats-drawer-elements-container"]}>
                  <DatePicker
                    variant="filled"
                    format={ConstantPickerDateFormat}
                    className={styles["chats-drawer-date"]}
                    placeholder={languageConstant[langKey].respondedDate}
                    defaultValue={payload.upd_dt ? dayjs(payload?.upd_dt, "MM-DD-YYYY") : null}
                    onChange={(date, datestring) => {
                      dispatch(
                        changeFilterUpdatedDate(
                          // luxon format MM -month
                          datestring
                            ? DateTime.fromFormat(datestring, "dd-MM-yyyy").toFormat("MM-dd-yyyy")
                            : ""
                        )
                      );
                    }}
                    disabledDate={disableFutureDates}
                  />
                </div>
              )}
            </div>
          </div>
        )}
        {(!orgError || !specError) && (
          <div className={styles["chats-drawer-buttons-div"]}>
            <Button
              type="primary"
              className="general-button-extra-large"
              disabled={orgLoading || specLoading}
              onClick={() => {
                dispatch(changeChatsFilterAppliedStatus(true));
                dispatch(changePageNumber(1));
                chatsFetchFuncton();
              }}
              loading={apiLoading}
            >
              {languageConstant[langKey].filter}
            </Button>
            <Button
              type="default"
              className="general-button-extra-large-secondary"
              disabled={orgLoading || specLoading}
              onClick={() => {
                dispatch(resetChatsPayloadFilter(constantChatPayload));
                dispatch(changeChatsFilterAppliedStatus(false));
                chatsFetchFuncton(constantChatPayload);
                handleCancel();
              }}
            >
              {languageConstant[langKey].clear}
            </Button>
          </div>
        )}
      </div>
    </Drawer>
  );
}
