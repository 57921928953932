import React, { useEffect, useRef, useState } from "react";
import { Typography } from "antd";

// store imports
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeOrganizationIsError } from "../../store/OrganizationSlice"; // eslint-disable-line max-len

// card import
import OrganizationCard from "./OrganizationCard";

//constants import
import languageConstant from "../../resources/constants/StringConstants";
import { DummyTableData } from "../../utils/ConstantObjects";

// styles import
import styles from "./Organization.module.scss";

// api function import
import { FetchOrganization } from "./FetchOrganization";

// component import
import SomethingWentWrong from "../errorPage/SomethingWentWrong";

export default function OrganizationMobileRows() {
  const { organizationData, payload, infiniteScrollLoading, totalRecords, langkey, isError } =
    useSelector(
      (store) => ({
        langkey: store.config.lang,
        organizationData: store.organization.organizationData,
        payload: store.organization.payload,
        infiniteScrollLoading: store.organization.infiniteScrollLoading,
        totalRecords: store.organization.totalRecords,
        isError: store.organization.isError,
      }),
      shallowEqual
    );
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const [localPage, setLocalPage] = useState(1);
  const [initialRendered, setInitialRendered] = useState(false);

  const fetchOrganizationFunction = async () => {
    const isErrorFlag = await FetchOrganization(
      dispatch,
      payload,
      localPage,
      initialRendered ? true : false
    );
    dispatch(changeOrganizationIsError(isErrorFlag));
  };

  useEffect(() => {
    fetchOrganizationFunction();
    if (!initialRendered) {
      setInitialRendered(true);
    }
  }, [localPage]);

  useEffect(() => {
    const currentDiv = divRef.current;

    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          handleScroll();
        }
      },
      { threshold: 0.2 }
    );

    if (currentDiv) {
      observer.observe(currentDiv);
    }

    return () => {
      if (currentDiv) {
        observer.unobserve(currentDiv);
      }
    };
  }, []);

  const handleScroll = () => {
    if (organizationData.length === totalRecords || infiniteScrollLoading || isError) {
      return;
    }
    setLocalPage((prevPage) => prevPage + 1);
  };

  if (isError) return <SomethingWentWrong refetch={fetchOrganizationFunction} />;

  return (
    <div className={styles["organization-card-main-scroll-div"]}>
      {organizationData.map((record) => (
        <OrganizationCard key={record.org_id} staff={record} forceLoading={false} />
      ))}

      {organizationData.length !== totalRecords && (
        <div ref={divRef}>
          {DummyTableData.map((record) => (
            <OrganizationCard key={record.id} forceLoading={true} />
          ))}
        </div>
      )}

      {organizationData.length === totalRecords && (
        <div className={styles["organization-reached-end-of-list-div"]}>
          <Typography className="reached-end-list-global-font">
            {languageConstant[langkey].reachedEndOfList}
          </Typography>
        </div>
      )}
    </div>
  );
}
