import React, { useEffect } from "react";
import { Avatar, Skeleton, Table } from "antd";

// styles import
import styles from "../Users.module.scss";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeSubmitterIsError,
  // updateSubmitterModalDto,
} from "../../../store/SubmitterSlice"; // eslint-disable-line max-len
// import {
//   changeCurrentSelectedUserModal,
//   changeUsersEditModalVisibility,
// } from "../../../store/UsersSlice";

// constants import
// import editIcon from "../../../resources/assets/edit.svg";
import defaultImg from "../../../resources/assets/defaultImg.svg";
import languageConstant from "../../../resources/constants/StringConstants";
// import { constantUsersList } from "../../../utils/ConstantObjects";

// component import
import SomethingWentWrong from "../../errorPage/SomethingWentWrong";

// api function import
import { FetchSubmitter } from "./FetchSubmitter";

export default function SubmitterTable() {
  const { langKey, payload, isLoading, userData, isError } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      payload: store.submitter.payload,
      isLoading: store.submitter.isLoading,
      userData: store.submitter.userData,
      isError: store.submitter.isError,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const fetchSubmitterFunction = async () => {
    const isErrorFlag = await FetchSubmitter(dispatch, payload, null, false);
    dispatch(changeSubmitterIsError(isErrorFlag));
  };
  useEffect(() => {
    fetchSubmitterFunction();
  }, []);

  // const handleEditButton = () => {
  //   dispatch(changeCurrentSelectedUserModal(constantUsersList[1]?.value));
  //   dispatch(
  //     updateSubmitterModalDto({
  //       newName: "danny",
  //       newAge: 8,
  //     })
  //   );
  //   dispatch(changeUsersEditModalVisibility(true));
  // };

  const columns = [
    {
      title: languageConstant[langKey].userName,
      render: (record) =>
        isLoading ? (
          <div className={styles["user-pic-info-div"]}>
            {/* add size of the image used below */}
            <Skeleton.Avatar active size={34} />
            <span style={{ marginTop: "auto" }}>
              <Skeleton.Input style={{ height: 12 }} active avatar />
            </span>
          </div>
        ) : (
          <div className={styles["user-pic-info-div"]}>
            <Avatar
              /* add size of the image used below */
              size={34}
              icon={
                <img
                  className={styles["users-profile-pic"]}
                  alt="Profile Pic"
                  src={record["image_url"] || defaultImg}
                />
              }
            />
            <span>
              {`${record["first_name"]} ${record["last_name"]}`.trim() ||
                languageConstant[langKey].noData}
            </span>
          </div>
        ),
    },
    {
      title: languageConstant[langKey].npi,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["npi"] || languageConstant[langKey].noData
        ),
    },

    {
      title: languageConstant[langKey].medicalGroup,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["medical_group_name"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].mobileNo,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["mobile"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].email,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["email"] || languageConstant[langKey].noData
        ),
    },
    // {
    //   title: languageConstant[langKey].actions,
    //   render: () =>
    //     isLoading ? (
    //       <Skeleton.Input style={{ height: 12 }} active />
    //     ) : (
    //       <Tooltip title={languageConstant[langKey].editSubmitter}>
    //         <img
    //           src={editIcon}
    //           alt="ai"
    //           className={styles["users-action-img"]}
    //           onClick={() => {
    //             handleEditButton();
    //           }}
    //         />
    //       </Tooltip>
    //     ),
    // },
  ];

  if (isError) return <SomethingWentWrong refetch={fetchSubmitterFunction} />;

  return (
    <div className={styles["users-table-container"]}>
      <Table
        dataSource={userData}
        rowKey={(record) => record["user_id"]}
        columns={columns}
        pagination={false}
        style={{ whiteSpace: "nowrap" }}
      />
    </div>
  );
}
