import metabaseAxiosInstance from "../http/MetabaseAxios";

export function getDashboard(id, token, dashboardId, role) {

  return metabaseAxiosInstance.post(`/embed/dashboard-${dashboardId}`, { 
    orgID: id, authToken: token, role: role});
}

export function wakeupMetabase() {
  return metabaseAxiosInstance.get("/wakeup");
}