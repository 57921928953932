import React from "react";
import { Typography } from "antd";
import PropTypes from "prop-types";

// styles import
import styles from "./Chats.module.scss";

export default function ChatsModalComponent({ name, message, time }) {
  return (
    <div className={styles["chats-modal-chat-container"]}>
      <Typography className={styles["chats-modal-chat-container-name"]}>{name}</Typography>
      <Typography className={styles["chats-modal-chat-container-message"]}>{message}</Typography>
      <Typography className={styles["chats-modal-chat-container-time"]}>{time}</Typography>
    </div>
  );
}
ChatsModalComponent.propTypes = {
  name: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  time: PropTypes.string.isRequired,
};
