import React from "react";
import { Outlet, RouterProvider, createBrowserRouter } from "react-router-dom";

// custom hook import
import { useAuthState } from "../hooks/useAuthState.js";

// constants import
import {
  PATH_CHATS,
  PATH_LOGIN,
  PATH_ORGANIZATION,
  PATH_USERS,
  PATH_INSIGHTS,
  PATH_REPORTS,
} from "../resources/constants/RoutesPathConstants";

// components import
import Login from "../components/login/Login.js";
import ErrorPage from "../components/errorPage/ErrorPage.js";
import NavigationMenu from "../components/navigationMenu/NavigationMenu.js";
import Chats from "../components/chats/Chats.js";
import Insights from "../components/insights/Insights.js";
import Reports from "../components/reports/Reports.js";

// store import
import { useSelector } from "react-redux";
import Users from "../components/users/Users.js";
import Organization from "../components/organization/Organization.js";

export default function RoutesComponent() {
  const location = window.location.pathname;
  const { isLoggedIn } = useAuthState();
  const isMobileMenuOpen = useSelector((store) => store.config.isMobileMenuOpen);
  const appRouter = createBrowserRouter([
    {
      path: PATH_LOGIN,
      element: (
        <div className="App">
          {isLoggedIn === "true" && location !== PATH_LOGIN && (
            <div className="header">
              <NavigationMenu />
            </div>
          )}
          <div
            className={`${isLoggedIn === "true" && location !== PATH_LOGIN ? "outlet" : ""}
          ${isMobileMenuOpen ? "outlet-blur" : ""}`}
          >
            <Outlet />
          </div>
        </div>
      ),
      children: [
        {
          path: PATH_LOGIN,
          element: <Login />,
        },
        {
          path: PATH_INSIGHTS,
          element: <Insights />,
        },
        {
          path: PATH_CHATS,
          element: <Chats />,
        },
        {
          path: PATH_USERS,
          element: <Users />,
        },
        {
          path: PATH_ORGANIZATION,
          element: <Organization />,
        },
        {
          path: PATH_REPORTS,
          element: <Reports />,
        },
      ],
      errorElement: (
        <div className="App">
          {isLoggedIn === "true" && location !== PATH_LOGIN && (
            <div className="header">
              <NavigationMenu />
            </div>
          )}
          <div className="outlet">
            <ErrorPage />
          </div>
        </div>
      ),
    },
  ]);

  return <RouterProvider router={appRouter}></RouterProvider>;
}
