import React from "react";
import { Button, Input } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

// styles import
import styles from "../Users.module.scss";

// constants import
import languageConstant from "../../../resources/constants/StringConstants";
import { constantUsersList } from "../../../utils/ConstantObjects";

// component import
import ResponderSubmitterProviderSizeChange from "./ResponderSubmitterProviderSizeChange";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeCurrentSelectedUserModal,
  changeUsersAddModalVisibility,
} from "../../../store/UsersSlice";
/* eslint-disable max-len */
import { updateResponderSubmitterProviderModalDto } from "../../../store/ResponderSubmitterProviderSlice";
/* eslint-enable max-len */

export default function ResponderSubmitterProviderHeader() {
  const { langKey, apiLoading } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      apiLoading: store.responderSubmitterProvider.isLoading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const handleAddButton = () => {
    dispatch(changeCurrentSelectedUserModal(constantUsersList[0]?.value));
    dispatch(
      updateResponderSubmitterProviderModalDto({
        newName: null,
        newAge: 0,
      })
    );
    dispatch(changeUsersAddModalVisibility(true));
  };

  const isMobile = window.innerWidth < 768; // threshold for mobile devices

  return (
    <div className={styles["users-header-container"]}>
      {!isMobile && <ResponderSubmitterProviderSizeChange />}
      <Input
        variant="filled"
        placeholder={languageConstant[langKey].searchNameEmail}
        className={styles["users-search"]}
        prefix={<SearchOutlined />}
        disabled={apiLoading}
      />

      <div className={styles["users-button-container"]}>
        <Button
          type="primary"
          className="general-button"
          onClick={() => {
            handleAddButton();
          }}
        >
          {languageConstant[langKey].add} <PlusOutlined style={{ fontSize: "17px" }} />
        </Button>
      </div>
    </div>
  );
}
