import { useState, useEffect } from "react";

export function useAuthState() {
  const [authToken, setAuthToken] = useState(sessionStorage.getItem("authToken") || null);
  const [isLoggedIn, setIsLoggedIn] = useState(sessionStorage.getItem("isLoggedIn") || false);

  useEffect(() => {
    // Function to handle changes in sessionStorage
    const handleStorageChange = () => {
      // Update authToken state whenever "authToken" in sessionStorage changes
      setAuthToken(sessionStorage.getItem("authToken"));
      setIsLoggedIn(sessionStorage.getItem("isLoggedIn"));
    };

    // Add event listener to listen for changes in sessionStorage
    window.addEventListener("storage", handleStorageChange);
    window.addEventListener("authTokenUpdated", handleStorageChange);

    return () => {
      window.removeEventListener("storage", handleStorageChange);
      window.removeEventListener("authTokenUpdated", handleStorageChange);
    };
  }, [authToken, isLoggedIn]);

  return { authToken, isLoggedIn };
}
