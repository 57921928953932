import React, { useEffect } from "react";
import { Avatar, Skeleton, Table, Tooltip } from "antd";

// styles import
import styles from "./Organization.module.scss";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeOrganizationIsError } from "../../store/OrganizationSlice"; // eslint-disable-line max-len

// constants import
import editIcon from "../../resources/assets/edit.svg";
import defaultImg from "../../resources/assets/defaultImg.svg";
import languageConstant from "../../resources/constants/StringConstants";
import { constantDateAndTimeFormat } from "../../utils/ConstantObjects";

// component import
import SomethingWentWrong from "../errorPage/SomethingWentWrong";

// api function import
import { FetchOrganization } from "./FetchOrganization";

// luxon date library import
import { DateTime } from "luxon";

export default function OrganizationTable() {
  const { langKey, payload, isLoading, organizationData, isError } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      payload: store.organization.payload,
      isLoading: store.organization.isLoading,
      organizationData: store.organization.organizationData,
      isError: store.organization.isError,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const fetchOrganizationFunction = async () => {
    const isErrorFlag = await FetchOrganization(dispatch, payload, null, false);
    dispatch(changeOrganizationIsError(isErrorFlag));
  };
  useEffect(() => {
    fetchOrganizationFunction();
  }, []);

  const columns = [
    {
      title: languageConstant[langKey].name,
      render: (record) =>
        isLoading ? (
          <div className={styles["organization-pic-info-div"]}>
            {/* add size of the image used below */}
            <Skeleton.Avatar active size={34} />
            <span style={{ marginTop: "auto" }}>
              <Skeleton.Input style={{ height: 12 }} active avatar />
            </span>
          </div>
        ) : (
          <div className={styles["organization-pic-info-div"]}>
            <Avatar
              /* add size of the image used below */
              size={34}
              icon={
                <img
                  className={styles["organization-profile-pic"]}
                  alt="Profile Pic"
                  src={record["image_url"] || defaultImg}
                />
              }
            />
            <span>{record["name"] || languageConstant[langKey].noData}</span>
          </div>
        ),
    },
    {
      title: languageConstant[langKey].type,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["organization_type.org_type"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].primaryContact,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["primary_contact"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].addedDate,
      render: (record) => {
        const formattedDateTime = DateTime.fromISO(record["createdAt"], {
          zone: "America/Los_Angeles",
        }).toLocaleString(constantDateAndTimeFormat);

        return isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : formattedDateTime !== "Invalid DateTime" ? (
          formattedDateTime
        ) : (
          languageConstant[langKey].noData
        );
      },
    },
    {
      title: languageConstant[langKey].address,
      render: (record) =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          record["address"] || languageConstant[langKey].noData
        ),
    },
    {
      title: languageConstant[langKey].actions,
      render: () =>
        isLoading ? (
          <Skeleton.Input style={{ height: 12 }} active />
        ) : (
          <Tooltip title={languageConstant[langKey].editOrganization}>
            <img
              src={editIcon}
              alt="ai"
              className={styles["organization-action-img"]}
              onClick={() => {}}
            />
          </Tooltip>
        ),
    },
  ];

  if (isError) return <SomethingWentWrong refetch={fetchOrganizationFunction} />;

  return (
    <div className={styles["organization-table-container"]}>
      <Table
        dataSource={organizationData}
        rowKey={(record) => record["org_id"]}
        columns={columns}
        pagination={false}
        style={{ whiteSpace: "nowrap" }}
      />
    </div>
  );
}
