import React from "react";
import { Button, Input, Typography } from "antd";
import { PlusOutlined, SearchOutlined } from "@ant-design/icons";

// styles import
import styles from "./Organization.module.scss";

// constants import
import languageConstant from "../../resources/constants/StringConstants";

// component import
import OrganizationSizeChange from "./OrganizationSizeChange";

// store import
import { shallowEqual, useSelector } from "react-redux";

export default function OrganizationHeader() {
  const { langKey, apiLoading } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      apiLoading: store.organization.isLoading,
    }),
    shallowEqual
  );

  const isMobile = window.innerWidth < 768; // threshold for mobile devices

  return (
    <div className={styles["organization-header-heading-buttons-div"]}>
      <div className={styles["organization-heading-container"]}>
        <Typography className={styles["organization-heading"]}>
          {languageConstant[langKey].organization}
        </Typography>
      </div>
      <br />
      <div className={styles["organization-header-container"]}>
        {!isMobile && <OrganizationSizeChange />}
        <Input
          variant="filled"
          placeholder={languageConstant[langKey].searchNameEmail}
          className={styles["organization-search"]}
          prefix={<SearchOutlined />}
          disabled={apiLoading}
        />

        <div className={styles["organization-button-container"]}>
          <Button type="primary" className="general-button">
            {languageConstant[langKey].add} <PlusOutlined style={{ fontSize: "17px" }} />
          </Button>
        </div>
      </div>
    </div>
  );
}
