import React from "react";
import { Button, Result } from "antd";

//router dom hook import
import { useRouteError, useNavigate } from "react-router-dom";

// constants import
import { PATH_INSIGHTS } from "../../resources/constants/RoutesPathConstants";
import languageConstant from "../../resources/constants/StringConstants";

// dispatcher, reducer slice
import { useDispatch, useSelector } from "react-redux";
import { changeMobileMenuStatus } from "../../store/ConfigSlice";

export default function ErrorPage() {
  const error = useRouteError();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const langKey = useSelector((store) => store.config.lang);

  return (
    <Result
      status={error?.status || "404"}
      title={error?.status || "404"}
      subTitle={error?.data || "Sorry, the page you visited does not exist."}
      extra={
        <Button
          type="primary"
          className="general-button"
          onClick={() => {
            navigate(PATH_INSIGHTS);
            dispatch(changeMobileMenuStatus(false));
          }}
        >
          {languageConstant[langKey].backHome}
        </Button>
      }
    />
  );
}
