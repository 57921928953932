const languageConstant = {
  en: {
    loginBrandingHeading1: "To put access to healthcare in the hands of every person in the world",
    loginBrandingHeading2:
      "Afya is your gateway to quickly, accurately and conveniently diagnose most medical conditions and get access to the appropriate care",
    loginFieldsHeading1: "Welcome back",
    loginFieldsHeading2:
      "There is no one who loves pain itself, who seeks after it and wants to have it, simply because it is pain",
    emailPlaceholder: "Email",
    passwordPlaceholder: "Password",
    LoginSignInText: "Sign In",
    ForgotPasswordText: "Forgot Password?",
    emailRequiredText: "We need your email address to continue.",
    emailPatternText: "Oops! Looks like that's not a valid email.",
    passwordRequiredText: "Your password is required for login.",
    sessionExpiredText: "Session expired, please login!",
    sessionExpiredTitleText: "Session Expired: Login Required",
    somethingWentWrongText: "Oops, something went wrong, let's try that again.",
    invalidCredentialsText:
      "Oops! It seems like the credentials entered are incorrect. Let's give it another shot.",
    invalidCredentialsTitleText: "Incorrect Credentials",
    userDoesNOtExistText:
      "Oops! It appears that the user doesn't exist in our system. Let's try again.",
    userDoesNotExistTitleText: "User Not Found",
    unexpectedErrorText: "An unexpected server error occurred. Please try again later.",
    unexpectedErrorTitleText: "Server Error",
    unexpectedNetworkGeneralErrorText:
      "An unexpected server error occurred. Please check your network connection and try again.",
    unexpectedNetworkGeneralErrorTitleText: "Something Went Wrong",
    chats: "Chats",
    organization: "Organization",
    users: "Users",
    systemSetup: "System Setup",
    reports: "Reports",
    insights: "Live Insights",
    tools: "Tools",
    backHome: "Back Home",
    welcomeBack: "Welcome Back,",
    totalChatSessions: "Total Chat Sessions",
    organizationsRegistered: "Orgs Registered",
    totalUsers: "Total Users",
    doctorsRegistered: "Doctors Registered",
    todaysEarnings: "Todays Earnings",
    earnings: "Earnings",
    chats: "Chats",
    noData: "No Data",
    showing: "Showing",
    to: "to",
    of: "of",
    entries: "entries",
    downloadCSV: "Download CSV",
    show: "Show",
    missingChatId: "Missing chat ID",
    chatDialogue: "Chat Dialogue",
    generateLink: "Generate Link",
    generateMeetingLink: "Generate Meeting Link",
    generateNewLink: "Generate New Link",
    linkCopiedToClipboard: "Link copied to clipboard!",
    missingSessionId: "Missing session ID",
    reassign: "Reassign",
    reassignChats: "Add another PCP to the Consultation",
    cancel: "Cancel",
    save: "Save",
    selectProvider: "Select / Search Provider",
    addResponderToChatSession: "Add a responder to a chat session!",
    removeUserFromChatSession: "Remove a user from a chat session!",
    addResponderProvider: "Add Responder Provider",
    removeResponderProvider: "Remove Responder Provider",
    remove: "Remove",
    newProvidersNotAdded: "New providers have not been added yet!",
    userRemovedFromChatSession: "User successfully removed from chat session!",
    pleaseChooseAProvider: "Please choose a provider!",
    failedToRemoveUserFromSession: "Failed to remove user from chat session.",
    userAddedToChatSession: "The user has been successfully added to the chat session!",
    failedToAddUserToSession: "User addition to the session was unsuccessful!",
    successfullyReassigned: "Successfully added PCP to Consultation!",
    failedToReassign: "Failed to add PCP!",
    filterTableRecords: "Filter table records!",
    filter: "Filter",
    clear: "Clear",
    selectOrganization: "Select / Search Organization",
    selectSpeciality: "Select / Search Speciality",
    speciality: "Speciality",
    organization: "Organization",
    search: "Search",
    searchUsingChatId: "Search using chat ID",
    searchUsingSubmiterName: "Search using submitter provider",
    searchUsingResponderName: "Search using responder provider",
    searchUsingConsumerName: "Search using consumer",
    searchUsingMedicalGroup: "Search using site",
    searchUsingNPI: "Search using NPI",
    date: "Date",
    createdDate: "Created Date",
    respondedDate: "Responded Date",
    serverErrorSomethingWentWrong: "Server error: Something went wrong",
    tryAgain: "Try again",
    chatId: "Chat Id",
    videoLink: "Video Link",
    medicalGroup: "Site",
    submitterProvider: "Submitter Provider",
    responderProvider: "Responder Provider",
    consumer: "Consumer",
    closeCode: "Recommendation",
    createdAt: "Created At",
    firstRespondedAt: "First Responded At",
    respondedAt: "Responded At",
    actions: "Actions",
    userAddedButFailedToAddBanner: "User added, but failed to add chat banner",
    userRemovedButFailedToAddBanner: "User removed, but failed to add chat banner",
    userReassignedButFailedToAddBanner: "User reassigned, but failed to add chat banner",
    hadAddedDoctor: "has added Dr.",
    toTheChat: "to the chat",
    dr: "Dr.",
    hasBeenRemovedFromTheChatBy: "has been removed from the chat by",
    logout: "Logout",
    selectRole: "Select / Search Role",
    userName: "User Name",
    hospital: "Hospital",
    clinicalOrg: "Clinical Org",
    mobileNo: "Mobile No",
    email: "Email",
    searchNameEmail: "Search name / email",
    add: "Add",
    phone: "Phone",
    reachedEndOfList: "You've reached the end of the list.",
    addedDate: "Added Date",
    user: "User",
    editResponderSubmitterProvider: "Edit Responder/Submitter Provider",
    clinicOrg: "Clinic Org",
    editSubmitterConsumer: "Edit Submitter Consumer",
    editOrganization: "Edit Organization",
    type: "Type",
    primaryContact: "Primary Contact",
    address: "Address",
    name: "Name",
    npi: "NPI",
    CDC: "CDC No",
    dob: "DOB",
    memberIdentifier: "Member Identifier", 
    addPCP: "Add Submitter",
    searchUsingSubmitterConsumerName: "Search using patient name",
    searchUsingMemberID: "Search using member ID",
    chatSessions: "Chat Sessions",
  },
};

export default languageConstant;
