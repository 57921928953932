import React from "react";
import { Button, Drawer, Input, Skeleton, Typography } from "antd";
import { SearchOutlined } from "@ant-design/icons";

// store import
import {
  changeSubmitterFilterAppliedStatus,
  changeSubmitterFilterCount,
  changeFilterDrawerVisibility,
  resetSubmitterPayloadOnCancelAndFilterNotApplied,
  changeFilterSubmitterName,
  changeFilterMedicalGroupName,
  changeFilterNPI,
  changeSubmitterIsError,
  changeSubmitterPageNo,
  resetPayloadFilter,
} from "../../../store/SubmitterSlice";
import { shallowEqual, useDispatch, useSelector } from "react-redux";

// styles import
import styles from "../Users.module.scss";

// constants import
import languageConstant from "../../../resources/constants/StringConstants";
// import { OK } from "../../resources/constants/ResponseCode";
import { constantSubmitterPayload } from "../../../utils/ConstantObjects";

// function import
import { FetchSubmitter } from "./FetchSubmitter";

// caching hook import
// import { useQuery } from "react-query";

// component import
// import PlainSomethingWentWrong from "../../errorPage/PlainSomethingWentWrong";

// helper function
import { appliedSubmitterFiltersCount } from "../../../utils/helperFunctions";

export default function FilterDrawer() {
  const { langKey, payload, apiLoading, filterApplied } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      payload: store.submitter.payload,
      apiLoading: store.submitter.isLoading,
      filterApplied: store.submitter.filterDrawer.filterApplied,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const handleCancel = () => {
    dispatch(changeFilterDrawerVisibility(false));
    if (!filterApplied) {
      dispatch(resetSubmitterPayloadOnCancelAndFilterNotApplied());
    } else if (filterApplied && appliedSubmitterFiltersCount(payload) === 0) {
      dispatch(changeSubmitterFilterCount(0));
      dispatch(changeSubmitterFilterAppliedStatus(false));
      submitterFetchFuncton(constantSubmitterPayload);
      dispatch(resetPayloadFilter(constantSubmitterPayload));
    }
  };

  const submitterFetchFuncton = async (constantSubmitterBody) => {
    const isErrorFlag = await FetchSubmitter(
      dispatch,
      constantSubmitterBody ? constantSubmitterBody : payload
    );
    dispatch(changeSubmitterIsError(isErrorFlag));
  };

  return (
    <Drawer
      title="Filter By"
      placement="right"
      closable={true}
      width={300}
      onClose={handleCancel}
      open={true}
    >
      <div className={styles["users-drawer-main-container"]}>
        <div className={styles["users-drawer-filter-div"]}>
          {/* {sessionStorage.getItem("role") === "Super Admin" && (
            <div className={styles["users-drawer-item-gap"]}>
              <Typography className={styles["users-filter-drawer-heading"]}>
                {languageConstant[langKey].organization}
              </Typography>
              {apiLoading? (
                <div className={styles["users-drawer-elements-container"]}>
                  <div className={styles["users-drawer-shimmer-div"]}>
                    <Skeleton.Input
                      active
                      style={{
                        position: "absolute",
                        width: "100%",
                        height: "40px",
                      }}
                    />
                  </div>
                </div>
              ) : (
                <div className={styles["users-drawer-elements-container"]}>
                  <Select
                    className={styles["users-drawer-select"]}
                    variant="filled"
                    showSearch
                    optionFilterProp="children"
                    placeholder={languageConstant[langKey].selectOrganization}
                    value={payload.org_array}
                    onChange={(e) => {
                      dispatch(changeFilterOrgIds(e));
                    }}
                    mode="multiple"
                  >
                    {orgList
                      .sort((a, b) => a.name.localeCompare(b.name))
                      .map((item) => (
                        <Select.Option key={item.org_id} value={item.org_id}>
                          {item.name}
                        </Select.Option>
                      ))}
                  </Select>
                </div>
              )}
            </div>
          )}  */}
          <div className={styles["users-drawer-item-gap"]}>
            <Typography className={styles["users-filter-drawer-heading"]}>
              {languageConstant[langKey].search}
            </Typography>
            {apiLoading ? (
              <div className={styles["users-drawer-elements-container"]}>
                <div className={styles["users-drawer-shimmer-div"]}>
                  <Skeleton.Input
                    active
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles["users-drawer-elements-container"]}>
                <Input
                  variant="filled"
                  placeholder={languageConstant[langKey].searchUsingSubmiterName}
                  className={styles["users-drawer-search"]}
                  prefix={<SearchOutlined />}
                  value={payload.sub_name}
                  onChange={(e) => {
                    dispatch(changeFilterSubmitterName(e.target.value));
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles["users-drawer-item-gap"]}>
            {apiLoading ? (
              <div className={styles["users-drawer-elements-container"]}>
                <div className={styles["users-drawer-shimmer-div"]}>
                  <Skeleton.Input
                    active
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles["users-drawer-elements-container"]}>
                <Input
                  variant="filled"
                  placeholder={languageConstant[langKey].searchUsingMedicalGroup}
                  className={styles["users-drawer-search"]}
                  prefix={<SearchOutlined />}
                  value={payload.medical_group}
                  onChange={(e) => {
                    dispatch(changeFilterMedicalGroupName(e.target.value));
                  }}
                />
              </div>
            )}
          </div>
          <div className={styles["users-drawer-item-gap"]}>
            {apiLoading ? (
              <div className={styles["users-drawer-elements-container"]}>
                <div className={styles["users-drawer-shimmer-div"]}>
                  <Skeleton.Input
                    active
                    style={{
                      position: "absolute",
                      width: "100%",
                      height: "40px",
                    }}
                  />
                </div>
              </div>
            ) : (
              <div className={styles["users-drawer-elements-container"]}>
                <Input
                  variant="filled"
                  placeholder={languageConstant[langKey].searchUsingNPI}
                  className={styles["users-drawer-search"]}
                  prefix={<SearchOutlined />}
                  value={payload.npi}
                  onChange={(e) => {
                    dispatch(changeFilterNPI(e.target.value));
                  }}
                />
              </div>
            )}
          </div>
        </div>

        <div className={styles["users-drawer-buttons-div"]}>
          <Button
            type="primary"
            className="general-button-extra-large"
            disabled={apiLoading}
            onClick={() => {
              dispatch(changeSubmitterFilterAppliedStatus(true));
              dispatch(changeSubmitterPageNo(1));
              submitterFetchFuncton();
            }}
            loading={apiLoading}
          >
            {languageConstant[langKey].filter}
          </Button>
          <Button
            type="default"
            className="general-button-extra-large-secondary"
            disabled={apiLoading}
            onClick={() => {
              dispatch(resetPayloadFilter(constantSubmitterPayload));
              dispatch(changeSubmitterFilterAppliedStatus(false));
              submitterFetchFuncton(constantSubmitterPayload);
              handleCancel();
            }}
          >
            {languageConstant[langKey].clear}
          </Button>
        </div>
      </div>
    </Drawer>
  );
}
