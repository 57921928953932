import React, { useEffect, useState } from "react";
import { Pagination, Skeleton, Typography } from "antd";

// styles import
import styles from "../Users.module.scss";

// redux store
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeSubmitterIsError,
  changeSubmitterPageNo,
} from "../../../store/SubmitterSlice";

// function import
import { FetchSubmitter } from "./FetchSubmitter";

// constant import
import languageConstant from "../../../resources/constants/StringConstants";

export default function SubmitterPagination() {
  const { pageNo, pageLimit, totalRecords, isLoading, langKey, body } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      pageNo: store.submitter.payload.page,
      pageLimit: store.submitter.payload.limit,
      totalRecords: store.submitter.totalRecords,
      body: store.submitter.payload,
      isLoading: store.submitter.isLoading,
    }),
    shallowEqual
  );
  const startIndex = (pageNo - 1) * pageLimit + 1;
  const endIndex = Math.min(pageNo * pageLimit, totalRecords);
  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    dispatch(changeSubmitterPageNo(page));
  };
  const [componentRendered, setComponentRendered] = useState(false);

  useEffect(() => {
    if (componentRendered) {
      fetchSubmitterFunction();
    }
    setComponentRendered(true);
  }, [pageNo]);

  const fetchSubmitterFunction = async () => {
    const isErrorFlag = await FetchSubmitter(dispatch, body, null, false);
    dispatch(changeSubmitterIsError(isErrorFlag));
  };

  return (
    <div className={styles["users-pagination-container"]}>
      <Typography className={styles["users-entries-typo"]}>
        {`${languageConstant[langKey].showing} 
        ${isLoading ? 0 : startIndex} ${languageConstant[langKey].to} 
        ${isLoading ? 0 : endIndex} ${languageConstant[langKey].of} ${isLoading ? 0 : totalRecords} 
        ${languageConstant[langKey].entries}`}
      </Typography>
      {isLoading ? (
        <Skeleton.Input active size="default" style={{ width: "200px" }} />
      ) : (
        <Pagination
          current={pageNo}
          total={totalRecords}
          pageSize={pageLimit}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      )}
    </div>
  );
}
