import React, { useEffect, useState } from "react";
import { Select, Typography } from "antd";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeSubmitterConsumerIsError,
  changeSubmitterConsumerPageLimit,
  changeSubmitterConsumerPageNo,
} from "../../../store/SubmitterConsumerSlice";

// constants import
import { PageSizeOptions } from "../../../utils/ConstantObjects";
import languageConstant from "../../../resources/constants/StringConstants";

// styles import
import styles from "../Users.module.scss";

// api function import
import { FetchSubmitterConsumer } from "./FetchSubmitterConsumer";

export default function SubmitterConsumerSizeChange() {
  const { langKey, pageLimit, body, apiLoading } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      pageLimit: store.submitterConsumer.payload.limit,
      body: store.submitterConsumer.payload,
      apiLoading: store.submitterConsumer.isLoading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [componentRendered, setComponentRendered] = useState(false);

  const handleSizeChange = (value) => {
    dispatch(changeSubmitterConsumerPageLimit(value));
    dispatch(changeSubmitterConsumerPageNo(1));
  };

  useEffect(() => {
    if (componentRendered) {
      fetchSubmitterConsumerFunction();
    }
    setComponentRendered(true);
  }, [pageLimit]);

  const fetchSubmitterConsumerFunction = async () => {
    const isErrorFlag = await FetchSubmitterConsumer(dispatch, body, null, false);
    dispatch(changeSubmitterConsumerIsError(isErrorFlag));
  };

  return (
    <div className={styles["users-size-change-container"]}>
      <Typography className={styles["users-entries-typo"]}>
        {languageConstant[langKey].show}
      </Typography>
      <Select
        defaultValue="10"
        onChange={handleSizeChange}
        options={PageSizeOptions}
        disabled={apiLoading}
      />
      <Typography className={styles["users-entries-typo"]}>
        {languageConstant[langKey].entries}
      </Typography>
    </div>
  );
}
