// Constans import
import { OK } from "../../../resources/constants/ResponseCode";

// api import

import { getResponderSubmitterProviderList } from "../../../services/apiServices/ResponderSubmitterProviderAPI"; // eslint-disable-line max-len

// store import
import {
  changeResponderSubmitterProviderData,
  changeResponderSubmitterProviderInfiniteScrollLoading,
  changeResponderSubmitterProviderIsLoading,
  changeResponderSubmitterProviderMobileData,
  changeResponderSubmitterProviderTotalRecords,
} from "../../../store/ResponderSubmitterProviderSlice";

export async function FetchResponderSubmitterProvider(
  dispatch,
  usersPayload,
  newPage,
  mobileFetch
) {
  try {
    if (mobileFetch) {
      dispatch(changeResponderSubmitterProviderInfiniteScrollLoading(true));
    } else {
      dispatch(changeResponderSubmitterProviderIsLoading(true));
    }
    const data = await getResponderSubmitterProviderList(
      mobileFetch ? { ...usersPayload, page: newPage } : usersPayload
    );
    if (data?.status === OK) {
      if (mobileFetch) {
        dispatch(changeResponderSubmitterProviderMobileData(data?.data?.rows));
      } else {
        dispatch(changeResponderSubmitterProviderData(data?.data?.rows));
      }
      dispatch(changeResponderSubmitterProviderTotalRecords(data?.data?.count));
    }

    return !data?.status === OK;
    // adding ! to satisty isError flag to render something went wrong
  } catch (error) {
    return true;
    // returning true to render something went wrong page
  } finally {
    dispatch(changeResponderSubmitterProviderIsLoading(false));
    dispatch(changeResponderSubmitterProviderInfiniteScrollLoading(false));
  }
}
