import React, { useEffect, useState } from "react";
import { Pagination, Skeleton, Typography } from "antd";

// styles import
import styles from "./Organization.module.scss";

// redux store
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeOrganizationIsError, changeOrganizationPageNo } from "../../store/OrganizationSlice";

// function import
import { FetchOrganization } from "./FetchOrganization";

// constant import
import languageConstant from "../../resources/constants/StringConstants";

export default function OrganizationPagination() {
  const { pageNo, pageLimit, totalRecords, isLoading, langKey, body } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      pageNo: store.organization.payload.page,
      pageLimit: store.organization.payload.limit,
      totalRecords: store.organization.totalRecords,
      body: store.organization.payload,
      isLoading: store.organization.isLoading,
    }),
    shallowEqual
  );
  const startIndex = (pageNo - 1) * pageLimit + 1;
  const endIndex = Math.min(pageNo * pageLimit, totalRecords);
  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    dispatch(changeOrganizationPageNo(page));
  };
  const [componentRendered, setComponentRendered] = useState(false);

  useEffect(() => {
    if (componentRendered) {
      fetchOrganizationFunction();
    }
    setComponentRendered(true);
  }, [pageNo]);

  const fetchOrganizationFunction = async () => {
    const isErrorFlag = await FetchOrganization(dispatch, body, null, false);
    dispatch(changeOrganizationIsError(isErrorFlag));
  };

  return (
    <div className={styles["organization-pagination-container"]}>
      <Typography className={styles["organization-entries-typo"]}>
        {`${languageConstant[langKey].showing} 
        ${isLoading ? 0 : startIndex} ${languageConstant[langKey].to} 
        ${isLoading ? 0 : endIndex} ${languageConstant[langKey].of} ${isLoading ? 0 : totalRecords} 
        ${languageConstant[langKey].entries}`}
      </Typography>
      {isLoading ? (
        <Skeleton.Input active size="default" style={{ width: "200px" }} />
      ) : (
        <Pagination
          current={pageNo}
          total={totalRecords}
          pageSize={pageLimit}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      )}
    </div>
  );
}
