import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export default function SubmitterModal() {
  const { modalDto } = useSelector(
    (store) => ({
      modalDto: store.Submitter.modalDto,
    }),
    shallowEqual
  );

  return (
    <div>
      Hello from Submitter
      <div>
        name : {modalDto.name} Age: {modalDto.age}
      </div>
    </div>
  );
}
