import { createSlice } from "@reduxjs/toolkit";

// constants import
import { DummyTableData, constantSubmitterConsumerPayload } from "../utils/ConstantObjects";

const SubmitterConsumerSlice = createSlice({
  name: "submitterConsumer",
  initialState: {
    isLoading: true,
    isError: false,
    infiniteScrollLoading: false,
    userData: DummyTableData,
    totalRecords: 0,
    payload: constantSubmitterConsumerPayload,
    filterDrawer: {
      visible: false,
      filterCount: 0,
      filterApplied: false,
    },
  },
  reducers: {
    changeSubmitterConsumerIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeSubmitterConsumerIsError: (state, action) => {
      state.isError = action.payload;
    },
    changeSubmitterConsumerInfiniteScrollLoading: (state, action) => {
      state.infiniteScrollLoading = action.payload;
    },
    changeSubmitterConsumerData: (state, action) => {
      state.userData = action.payload;
    },
    changeSubmitterConsumerMobileData: (state, action) => {
      const payload = action.payload || {};

      const newUserData = [...state.userData, ...payload];

      state.userData = newUserData;
    },
    changeSubmitterConsumerTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    changeSubmitterConsumerPageNo: (state, action) => {
      state.payload.page = action.payload;
    },
    changeSubmitterConsumerPageLimit: (state, action) => {
      state.payload.limit = action.payload;
    },
    resetSubmitterConsumerPayload: (state, action) => {
      state.payload = action.payload;
    },
    changeFilterDrawerVisibility: (state, action) => {
      state.filterDrawer.visible = action.payload;
    },
    changeSubmitterConsumerFilterCount: (state, action) => {
      state.filterDrawer.filterCount = action.payload;
    },
    changeSubmitterConsumerFilterAppliedStatus: (state, action) => {
      state.filterDrawer.filterApplied = action.payload;
    },
    changeFilterSubmitterConsumerName: (state, action) => {
      /* eslint-disable camelcase */
      state.payload.patient_name = action.payload;
    },
    changeFilterMemberID: (state, action) => {
      state.payload.memberID = action.payload;
    },
    resetPayloadFilter: (state, action) => {
      state.payload = action.payload;
    },
    resetSubmitterConsumerPayloadOnCancelAndFilterNotApplied: (state) => {
      /* eslint-disable camelcase */
      state.payload = {
        ...state.payload,
        patient_name: "",
        memberID: "",
      };
    },
  },
});

export const {
  changeSubmitterConsumerIsLoading,
  changeSubmitterConsumerIsError,
  changeSubmitterConsumerInfiniteScrollLoading,
  changeSubmitterConsumerData,
  changeSubmitterConsumerMobileData,
  changeSubmitterConsumerTotalRecords,
  changeSubmitterConsumerPageNo,
  changeSubmitterConsumerPageLimit,
  resetSubmitterConsumerPayload,
  changeFilterDrawerVisibility,
  changeFilterSubmitterConsumerName,
  changeFilterMemberID,
  resetSelectedDrawerFilter,
  resetPayloadFilter,
  changeSubmitterConsumerFilterCount,
  changeSubmitterConsumerFilterAppliedStatus,
  resetSubmitterConsumerPayloadOnCancelAndFilterNotApplied,
} = SubmitterConsumerSlice.actions;

export default SubmitterConsumerSlice.reducer;
