import { configureStore } from "@reduxjs/toolkit";

// slice import
import configReducer from "./ConfigSlice.js";
import chatsReducer from "./ChatSlice.js";
import responderSubmitterProviderReducer from "./ResponderSubmitterProviderSlice.js";
import submitterConsumerReducer from "./SubmitterConsumerSlice.js";
import submitterReducer from "./SubmitterSlice.js";
import organizationReducer from "./OrganizationSlice.js";
import usersReducer from "./UsersSlice.js";

const AppStore = configureStore({
  reducer: {
    config: configReducer,
    chats: chatsReducer,
    responderSubmitterProvider: responderSubmitterProviderReducer,
    submitterConsumer: submitterConsumerReducer,
    submitter: submitterReducer,
    organization: organizationReducer,
    users: usersReducer,
  },
});

export default AppStore;
