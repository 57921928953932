import React, { useEffect, useRef, useState } from "react";
import { Typography } from "antd";

// store imports
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeResponderSubmitterProviderIsError } from "../../../store/ResponderSubmitterProviderSlice"; // eslint-disable-line max-len

// card import
import ResponderSubmitterProviderCard from "./ResponderSubmitterProviderCard";

//constants import
import languageConstant from "../../../resources/constants/StringConstants";
import { DummyTableData } from "../../../utils/ConstantObjects";

// styles import
import styles from "../Users.module.scss";

// api function import
import { FetchResponderSubmitterProvider } from "./FetchResponderSubmitterProvider";

// component import
import SomethingWentWrong from "../../errorPage/SomethingWentWrong";

export default function ResponderSubmitterProviderMobileRows() {
  const { userData, payload, infiniteScrollLoading, totalRecords, langkey, isError } = useSelector(
    (store) => ({
      langkey: store.config.lang,
      userData: store.responderSubmitterProvider.userData,
      payload: store.responderSubmitterProvider.payload,
      infiniteScrollLoading: store.responderSubmitterProvider.infiniteScrollLoading,
      totalRecords: store.responderSubmitterProvider.totalRecords,
      isError: store.responderSubmitterProvider.isError,
    }),
    shallowEqual
  );
  const divRef = useRef(null);
  const dispatch = useDispatch();
  const [localPage, setLocalPage] = useState(1);
  const [initialRendered, setInitialRendered] = useState(false);

  const fetchResponderSubmitterProviderFunction = async () => {
    const isErrorFlag = await FetchResponderSubmitterProvider(
      dispatch,
      payload,
      localPage,
      initialRendered ? true : false
    );
    dispatch(changeResponderSubmitterProviderIsError(isErrorFlag));
  };

  useEffect(() => {
    fetchResponderSubmitterProviderFunction();
    setInitialRendered(true);
  }, [localPage]);

  const handleScroll = () => {
    if (userData.length === totalRecords || infiniteScrollLoading || isError) {
      return;
    }
    const div = divRef.current;
    if (div.scrollTop + div.clientHeight >= div.scrollHeight - 1) {
      setLocalPage((prevPage) => prevPage + 1);
    }
  };

  if (isError) return <SomethingWentWrong refetch={fetchResponderSubmitterProviderFunction} />;

  return (
    <div
      onScroll={() => {
        handleScroll();
      }}
      ref={divRef}
      className={styles["users-card-main-scroll-div"]}
    >
      {userData.map((record) => (
        <ResponderSubmitterProviderCard key={record.user_id} staff={record} forceLoading={false} />
      ))}

      {infiniteScrollLoading &&
        DummyTableData.map((record) => (
          <ResponderSubmitterProviderCard key={record.id} forceLoading={true} />
        ))}

      {userData.length === totalRecords && (
        <div className={styles["users-reached-end-of-list-div"]}>
          <Typography className="reached-end-list-global-font">
            {languageConstant[langkey].reachedEndOfList}
          </Typography>
        </div>
      )}
    </div>
  );
}
