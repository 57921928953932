import { notification } from "antd";

const useNotification = () => {
  const openNotification = (type, message, description) => {
    // type can be success, info, warning, error.
    notification[type]({
      message,
      description,
      placement: "topRight",
    });
  };

  return { openNotification };
};

export default useNotification;
