import React, { useState } from "react";
import { Button, Modal, Select, Skeleton, message } from "antd";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeAddResponderModalVisibility } from "../../store/ChatSlice";

// constant import
import languageConstant from "../../resources/constants/StringConstants";
import { OK } from "../../resources/constants/ResponseCode";
import { ACTIONS, DATATYPES } from "../../utils/ConstantObjects";

// styles import
import styles from "./Chats.module.scss";

// api import
import {
  addChatData,
  addRemoveUserFromSession,
  getUserListForAddResponder,
} from "../../services/apiServices/ChatsAPI";

// component import
import PlainSomethingWentWrong from "../errorPage/PlainSomethingWentWrong";

// chaching hook import
import { useQuery } from "react-query";

export default function AddResponderModal() {
  const { visible, langKey, payload, chatSessionId } = useSelector(
    (store) => ({
      visible: store.chats.addResponderModal.visible,
      payload: store.chats.addResponderModal.payload,
      langKey: store.config.lang,
      chatSessionId: store.chats.chatSessionId,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(changeAddResponderModalVisibility(false));
  };
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [loading, setLoading] = useState(false);

  const getUserList = async () => {
    try {
      const response = await getUserListForAddResponder(payload);
      if (response?.status === OK) {
        return response?.data;
      }
    } catch (error) {
      throw error;
    }
  };

  const {
    data: usersList,
    isLoading,
    isError,
    refetch,
  } = useQuery("addResponderModalQuery", getUserList, {
    retry: false,
  });

  const addUserToSession = async () => {
    try {
      if (!selectedUserId) {
        message.error({
          content: languageConstant[langKey].pleaseChooseAProvider,
          style: {
            marginTop: "12vh",
          },
        });

        return;
      }

      /* eslint-disable camelcase */
      const payload = {
        action: ACTIONS.ADD,
        session_id: chatSessionId,
        user_id: selectedUserId,
      };
      setLoading(true);
      /* eslint-enable camelcase */
      const data = await addRemoveUserFromSession(payload);
      if (data?.status === OK) {
        const selectedProviderName = `${data?.data["first_name"]} ${data?.data["last_name"]}`;
        const bannerStatus = await addChatBanner(selectedProviderName);
        if (bannerStatus) {
          handleCancel();
          message.success({
            content: languageConstant[langKey].userAddedToChatSession,
            style: {
              marginTop: "12vh",
            },
          });
          setSelectedUserId(null);
        } else {
          message.error({
            content: languageConstant[langKey].userAddedButFailedToAddBanner,
            style: {
              marginTop: "12vh",
            },
          });
        }
      } else {
        message.error({
          content: languageConstant[langKey].failedToAddUserToSession,
          style: {
            marginTop: "12vh",
          },
        });
      }
    } catch (error) {
      // empty catch block since error is already handled in interceptors
    } finally {
      setLoading(false);
    }
  };

  const addChatBanner = async (selectedProviderName) => {
    try {
      const fromName = `${sessionStorage.getItem("firstName")} 
      ${sessionStorage.getItem("lastName")}`;

      /* eslint-disable camelcase */
      const payload = {
        speciality: "", //passing empty string as speciality id since this is super admin account
        from_id: sessionStorage.getItem("userId"),
        session_id: chatSessionId,
        from_name: fromName,
        datatype: DATATYPES.notes,
        message: `${fromName} ${languageConstant[langKey].hadAddedDoctor} 
        ${selectedProviderName} ${languageConstant[langKey].toTheChat}`,
        link: "",
        img_url: "",
      };
      /* eslint-enable camelcase */

      const data = await addChatData(payload);

      return data?.status === OK;
    } catch (error) {
      return false;
      // empty catch block since error is already handled in interceptors
    }
  };

  return (
    <Modal
      title={languageConstant[langKey].addResponderProvider}
      centered
      open={visible}
      footer={
        !isError && (
          <div className="global-footer-container">
            <Button type="primary" className="general-button-large" onClick={handleCancel}>
              {languageConstant[langKey].cancel}
            </Button>

            <Button
              type="primary"
              className="general-button-large"
              onClick={() => {
                addUserToSession();
              }}
              disabled={loading || isLoading}
            >
              {languageConstant[langKey].save}
            </Button>
          </div>
        )
      }
      width={800}
      onCancel={handleCancel}
    >
      <hr />
      {loading || isLoading ? (
        <div className={styles["chats-add-responder-modal-container"]}>
          <div className={styles["chats-add-responder-shimmer-container"]}>
            <Skeleton.Input
              active
              style={{
                position: "absolute",
                width: "100%",
                height: "40px",
              }}
            />
          </div>
        </div>
      ) : isError ? (
        <PlainSomethingWentWrong refetch={refetch} />
      ) : (
        <div className={styles["chats-add-responder-modal-container"]}>
          <Select
            className={styles["chats-add-responder-select"]}
            variant="filled"
            showSearch
            optionFilterProp="children"
            placeholder={languageConstant[langKey].selectProvider}
            onChange={(e) => {
              setSelectedUserId(e);
            }}
          >
            {usersList
              .sort((a, b) => a.first_name.localeCompare(b.first_name))
              .map((item) => (
                <Select.Option key={item.user_id} value={item.user_id}>
                  {item.first_name}
                  {item.last_name}
                </Select.Option>
              ))}
          </Select>
        </div>
      )}
    </Modal>
  );
}
