import { useEffect, useState } from "react";
import PropTypes from "prop-types";

//custom hook
import { useAuthState } from "../../hooks/useAuthState";
import useNotification from "../../hooks/useNotification";

//custom axios instance
import axiosInstance from "./HTTP";

//constants import
import {
  SOMETHING_WENT_WRONG,
  UNAUTHORIZED,
  USER_DOES_NOT_EXIST,
  WRONG_CREDENTIALS,
} from "../../resources/constants/ResponseCode";
import languageConstant from "../../resources/constants/StringConstants";
import { PATH_INSIGHTS, PATH_LOGIN } from "../../resources/constants/RoutesPathConstants";
import afyaLogo from "../../resources/assets/afya-icon.svg";

// store import
import { useSelector } from "react-redux";

export function HttpProvider({ children }) {
  const { authToken } = useAuthState();
  const [initialized, setInitialized] = useState(false);
  const langKey = useSelector((store) => store.config.lang);
  const { openNotification } = useNotification();

  useEffect(() => {
    if (window.location.pathname !== PATH_LOGIN && authToken === null) {
      sessionStorage.clear();
      window.location.href = PATH_LOGIN;

      return;
    }
    if (window.location.pathname === PATH_LOGIN && authToken !== null) {
      window.location.pathname = PATH_INSIGHTS;

      return;
    }
    // clear instances
    axiosInstance.interceptors.request.clear();
    axiosInstance.interceptors.response.clear();

    // Initialize axios instance with necessary configurations
    axiosInstance.interceptors.request.use(
      function onRequestSuccess(config) {
        // Add authorization and other headers!!
        config.headers["Accept"] = "application/json";
        config.headers["Auth"] = authToken;
        config.headers["locale"] = "en"; // Set locale header

        return config;
      },
      function onRequestError(error) {
        return Promise.reject(error);
      }
    );

    axiosInstance.interceptors.response.use(
      function onResponseSuccess(response) {
        // Any status code that lie within the range of 2xx cause this function to trigger

        return response;
      },
      function onResponseError(error) {
        if (error?.response) {
          /* eslint-disable prefer-destructuring */
          const status = error?.response?.status;
          /* eslint-enable prefer-destructuring */
          if (status === UNAUTHORIZED) {
            openNotification(
              "error",
              languageConstant[langKey].sessionExpiredTitleText,
              languageConstant[langKey].sessionExpiredText
            );
            sessionStorage.clear();
            setTimeout(() => {
              window.location.href = PATH_LOGIN;
            }, 1000);
          } else if (status === SOMETHING_WENT_WRONG) {
            openNotification(
              "error",
              languageConstant[langKey].unexpectedNetworkGeneralErrorTitleText,
              languageConstant[langKey].somethingWentWrongText
            );
          } else if (status === WRONG_CREDENTIALS) {
            openNotification(
              "error",
              languageConstant[langKey].invalidCredentialsTitleText,
              languageConstant[langKey].invalidCredentialsText
            );
          } else if (status === USER_DOES_NOT_EXIST) {
            openNotification(
              "error",
              languageConstant[langKey].userDoesNotExistTitleText,
              languageConstant[langKey].userDoesNOtExistText
            );
          } else {
            // Handle other error status with a generic message
            openNotification(
              "error",
              languageConstant[langKey].unexpectedErrorTitleText,
              languageConstant[langKey].unexpectedErrorText
            );
          }
        } else {
          // Handle network errors or other unexpected errors
          openNotification(
            "error",
            languageConstant[langKey].unexpectedNetworkGeneralErrorTitleText,
            languageConstant[langKey].unexpectedNetworkGeneralErrorText
          );
        }

        return Promise.reject(error);
      }
    );

    setInitialized(true);
  }, [authToken]);

  // Render children only if initialized
  return initialized ? (
    children
  ) : (
    <div className="container">
      <img src={afyaLogo} alt="Afya Logo" />
      <div className="loader"></div>
    </div>
  );
}

HttpProvider.propTypes = {
  children: PropTypes.node,
};
