import React from "react";
import { Avatar, Badge, Tooltip  } from "antd";
// import { PlusOutlined, SearchOutlined } from "@ant-design/icons";
import { shallowEqual, useSelector, useDispatch } from "react-redux";

// styles import
import styles from "../Users.module.scss";

// constants import
import languageConstant from "../../../resources/constants/StringConstants";
import filterIcon from "../../../resources/assets/filter.svg";

// component import
import SubmitterConsumerSizeChange from "./SubmitterConsumerSizeChange";
import SubmitterConsumerFilterDrawer from "./SubmitterConsumerFilterDrawer";

// store import
import {
  changeFilterDrawerVisibility,
} from "../../../store/SubmitterConsumerSlice";

export default function SubmitterConsumerHeader() {
  const dispatch = useDispatch();
  const { langKey,filterCount, renderFilterDrawer } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      apiLoading: store.submitterConsumer.isLoading,
      renderFilterDrawer: store.submitterConsumer.filterDrawer.visible,
      filterCount: store.submitterConsumer.filterDrawer.filterCount,
    }),
    shallowEqual
  );

  const isMobile = window.innerWidth < 768; // threshold for mobile devices

  return (
    <div className={styles["users-header-container"]}>
      {!isMobile && <SubmitterConsumerSizeChange />}
      <div className={styles["users-buttons-container"]}>
        {/* <Button type="primary" className="general-button">
          {languageConstant[langKey].add} <PlusOutlined style={{ fontSize: "17px" }} />
        </Button> */}
        <Badge
          count={filterCount || 0}
          overflowCount={10}
          onClick={() => {
            dispatch(changeFilterDrawerVisibility(true));
          }}
        >
          <Tooltip title={languageConstant[langKey].filterTableRecords}>
            <Avatar shape="round" size="default" className={styles["users-avatar"]}>
              <img
                src={filterIcon}
                className={styles["users-filter-image"]}
                style={{
                  maxWidth: "16px",
                  maxHeight: "16px",
                  marginTop: "20%",
                  userSelect: "none",
                }}
                alt="filter"
              />
            </Avatar>
          </Tooltip>
        </Badge>
      </div>
      {renderFilterDrawer && <SubmitterConsumerFilterDrawer />}
    </div>
  );
}
