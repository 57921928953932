import React, { useEffect } from "react";
import { Typography } from "antd";

// css import
import styles from "./Login.module.scss";

// images import
import medicalTeam from "../../resources/assets/medical-team.svg";

//constants import
import languageConstant from "../../resources/constants/StringConstants";

//api import
import { wakeupMetabase } from "../../services/apiServices/MetabaseAPI";

//componet import
import LoginFields from "./LoginFields";

// store import
import { useSelector } from "react-redux";

export default function Login() {
  const langKey = useSelector((store) => store.config.lang);

  useEffect(() => {
    // API call to wake up Metabase instance
    const wakeUpMetabase = async () => {
      try {
        await wakeupMetabase();
        console.log("Metabase instance is awake!");
      } catch (error) {
        console.error("Failed to wake up Metabase instance:", error);
      }
    };

    wakeUpMetabase();
  }, []);

  return (
    <div className={styles["login-main-container-div"]}>
      <div className={styles["login-main-container-branding-div"]}>
        <Typography className={styles["login-main-container-branding-div-heading-1"]}>
          {languageConstant[langKey].loginBrandingHeading1}
        </Typography>
        <Typography className={styles["login-main-container-branding-div-heading-2"]}>
          {languageConstant[langKey].loginBrandingHeading2}
        </Typography>
        <img
          src={medicalTeam}
          alt=""
          className={styles["login-main-container-branding-div-img-1"]}
        />
      </div>
      <LoginFields />
    </div>
  );
}
