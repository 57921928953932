// import { USER_DOES_NOT_EXIST } from "../../resources/constants/ResponseCode";
import { OK } from "../../resources/constants/ResponseCode";
import { loginUserWithEmailAndPassword } from "../../services/apiServices/AuthAPI";

export async function LoginLogic(dto) {
  try {
    const data = await loginUserWithEmailAndPassword(dto);
    if (data?.status === OK) {
      sessionStorage.setItem("userId", data?.data?.["user_id"]);
      sessionStorage.setItem("role", data?.data?.["role.role_name"]);
      sessionStorage.setItem("firstName", data?.data?.["first_name"]);
      sessionStorage.setItem("lastName", data?.data?.["last_name"]);
      sessionStorage.setItem("isLoggedIn", true);
      sessionStorage.setItem("authToken", data?.data?.token);
      sessionStorage.setItem("userEmail", data?.data?.email);
      sessionStorage.setItem("organizationId", data?.data?.org_id);
      window.dispatchEvent(new Event("authTokenUpdated"));
      
      return true;
    }
  } catch (error) {
    // empty catch block since error is already handled in interceptors
  }

  return false;
}
