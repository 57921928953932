import React, { useEffect } from "react";
import { Avatar, Skeleton, Table, Tooltip } from "antd";

// styles import
import styles from "../Users.module.scss";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeSubmitterConsumerIsError } from "../../../store/SubmitterConsumerSlice"; // eslint-disable-line max-len

// constants import
import editIcon from "../../../resources/assets/edit.svg";
import defaultImg from "../../../resources/assets/defaultImg.svg";
import languageConstant from "../../../resources/constants/StringConstants";

// component import
import SomethingWentWrong from "../../errorPage/SomethingWentWrong";

// api function import
import { FetchSubmitterConsumer } from "./FetchSubmitterConsumer";

export default function SubmitterConsumerTable() {
  const { langKey, payload, isLoading, userData, isError } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      payload: store.submitterConsumer.payload,
      isLoading: store.submitterConsumer.isLoading,
      userData: store.submitterConsumer.userData,
      isError: store.submitterConsumer.isError,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const fetchSubmitterConsumerFunction = async () => {
    const isErrorFlag = await FetchSubmitterConsumer(dispatch, payload, null, false);
    dispatch(changeSubmitterConsumerIsError(isErrorFlag));
  };
  useEffect(() => {
    fetchSubmitterConsumerFunction();
  }, []);

  const columns =
    sessionStorage.getItem("role") == "Super Admin"
      ? [
        {
          title: languageConstant[langKey].userName,
          render: (record) =>
            isLoading ? (
              <div className={styles["user-pic-info-div"]}>
                {/* add size of the image used below */}
                <Skeleton.Avatar active size={34} />
                <span style={{ marginTop: "auto" }}>
                  <Skeleton.Input style={{ height: 12 }} active avatar />
                </span>
              </div>
            ) : (
              <div className={styles["user-pic-info-div"]}>
                <Avatar
                  /* add size of the image used below */
                  size={34}
                  icon={
                    <img
                      className={styles["users-profile-pic"]}
                      alt="Profile Pic"
                      src={record["image_url"] || defaultImg}
                    />
                  }
                />
                <span>
                  {`${record["first_name"]} ${record["last_name"]}`.trim() ||
                    languageConstant[langKey].noData}
                </span>
              </div>
            ),
        },
        {
          title: languageConstant[langKey].mobileNo,
          render: (record) =>
            isLoading ? (
              <Skeleton.Input style={{ height: 12 }} active />
            ) : (
              record["mobile"] || languageConstant[langKey].noData
            ),
        },
        {
          title: languageConstant[langKey].organization,
          render: (record) =>
            isLoading ? (
              <Skeleton.Input style={{ height: 12 }} active />
            ) : (
              record["organization.name"] || languageConstant[langKey].noData
            ),
        },
        {
          title: languageConstant[langKey].CDC,
          render: (record) =>
            isLoading ? (
              <Skeleton.Input style={{ height: 12 }} active />
            ) : (
              record["member_identifier"] || languageConstant[langKey].noData
            ),
        },
        {
          title: languageConstant[langKey].email,
          render: (record) =>
            isLoading ? (
              <Skeleton.Input style={{ height: 12 }} active />
            ) : (
              record["email"] || languageConstant[langKey].noData
            ),
        },
        {
          title: languageConstant[langKey].actions,
          render: () =>
            isLoading ? (
              <Skeleton.Input style={{ height: 12 }} active />
            ) : (
              <Tooltip title={languageConstant[langKey].editSubmitterConsumer}>
                <img
                  src={editIcon}
                  alt="ai"
                  className={styles["users-action-img"]}
                  onClick={() => {}}
                />
              </Tooltip>
            ),
        },
      ]
      : sessionStorage.getItem("organizationId") == "700fb980-3e18-11ee-8e5a-9995b6ce38a9"? 
        [
          {
            title: languageConstant[langKey].userName,
            render: (record) =>
              isLoading ? (
                <div className={styles["user-pic-info-div"]}>
                  {/* add size of the image used below */}
                  <Skeleton.Avatar active size={34} />
                  <span style={{ marginTop: "auto" }}>
                    <Skeleton.Input style={{ height: 12 }} active avatar />
                  </span>
                </div>
              ) : (
                <div className={styles["user-pic-info-div"]}>
                  <Avatar
                    /* add size of the image used below */
                    size={34}
                    icon={
                      <img
                        className={styles["users-profile-pic"]}
                        alt="Profile Pic"
                        src={record["image_url"] || defaultImg}
                      />
                    }
                  />
                  <span>
                    {`${record["first_name"]} ${record["last_name"]}`.trim() ||
                      languageConstant[langKey].noData}
                  </span>
                </div>
              ),
          },
          {
            title: languageConstant[langKey].CDC,
            render: (record) =>
              isLoading ? (
                <Skeleton.Input style={{ height: 12 }} active />
              ) : (
                record["member_identifier"] || languageConstant[langKey].noData
              ),
          },
          {
            title: languageConstant[langKey].dob,
            render: (record) =>
              isLoading ? (
                <Skeleton.Input style={{ height: 12 }} active />
              ) : (
                record["dob"] || languageConstant[langKey].noData
              ),
          },
          // {
          //   title: languageConstant[langKey].actions,
          //   render: () =>
          //     isLoading ? (
          //       <Skeleton.Input style={{ height: 12 }} active />
          //     ) : (
          //       <Tooltip title={languageConstant[langKey].editSubmitterConsumer}>
          //         <img
          //           src={editIcon}
          //           alt="ai"
          //           className={styles["users-action-img"]}
          //           onClick={() => {}}
          //         />
          //       </Tooltip>
          //     ),
          // },
        ]:[
          {
            title: languageConstant[langKey].userName,
            render: (record) =>
              isLoading ? (
                <div className={styles["user-pic-info-div"]}>
                  {/* add size of the image used below */}
                  <Skeleton.Avatar active size={34} />
                  <span style={{ marginTop: "auto" }}>
                    <Skeleton.Input style={{ height: 12 }} active avatar />
                  </span>
                </div>
              ) : (
                <div className={styles["user-pic-info-div"]}>
                  <Avatar
                    /* add size of the image used below */
                    size={34}
                    icon={
                      <img
                        className={styles["users-profile-pic"]}
                        alt="Profile Pic"
                        src={record["image_url"] || defaultImg}
                      />
                    }
                  />
                  <span>
                    {`${record["first_name"]} ${record["last_name"]}`.trim() ||
                      languageConstant[langKey].noData}
                  </span>
                </div>
              ),
          },
          {
            title: languageConstant[langKey].memberIdentifier,
            render: (record) =>
              isLoading ? (
                <Skeleton.Input style={{ height: 12 }} active />
              ) : (
                record["member_identifier"] || languageConstant[langKey].noData
              ),
          },
          {
            title: languageConstant[langKey].mobileNo,
            render: (record) =>
              isLoading ? (
                <Skeleton.Input style={{ height: 12 }} active />
              ) : (
                record["mobile"] || languageConstant[langKey].noData
              ),
          },
          {
            title: languageConstant[langKey].email,
            render: (record) =>
              isLoading ? (
                <Skeleton.Input style={{ height: 12 }} active />
              ) : (
                record["email"] || languageConstant[langKey].noData
              ),
          },
          // {
          //   title: languageConstant[langKey].actions,
          //   render: () =>
          //     isLoading ? (
          //       <Skeleton.Input style={{ height: 12 }} active />
          //     ) : (
          //       <Tooltip title={languageConstant[langKey].editSubmitterConsumer}>
          //         <img
          //           src={editIcon}
          //           alt="ai"
          //           className={styles["users-action-img"]}
          //           onClick={() => {}}
          //         />
          //       </Tooltip>
          //     ),
          // },
        ];

  if (isError) return <SomethingWentWrong refetch={fetchSubmitterConsumerFunction} />;

  return (
    <div className={styles["users-table-container"]}>
      <Table
        dataSource={userData}
        rowKey={(record) => record["user_id"]}
        columns={columns}
        pagination={false}
        style={{ whiteSpace: "nowrap" }}
      />
    </div>
  );
}
