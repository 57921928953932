import axiosInstance from "../http/HTTP";
import customInstance from "../http/HTTP_custom";

export function getAllChats(dto) {
  return axiosInstance.post("/chat/session_for_admin", dto);
}

export function getChatsByChatId(dto) {
  return axiosInstance.post("/chat/get_chats", dto);
}

export function getVideoCallLink(dto) {
  return axiosInstance.post("/chat/add_video_call_link", dto);
}

export function userAutoAssign(dto) {
  return axiosInstance.post("/user_management/auto_assign", dto);
}

export function pcpAutoAssign(dto) {
  return customInstance.post("/get_all_pcps", dto);
}

export function getUserListForAddResponder(dto) {
  return axiosInstance.post("/super_admin/user_list_for_add_user", dto);
}

export function getSessionDetails(dto) {
  return axiosInstance.post("/chat/session_details", dto);
}

export function addRemoveUserFromSession(dto) {
  return axiosInstance.post("/chat/add_remove_user_from_session", dto);
}

export function manuallyAssignUser(dto) {
  console.log(dto)

  return dto
  // return axiosInstance.post("/user_management/mannual_assign", dto);
}

export function manuallyAssignPCP(dto) {
  return customInstance.post("/assign_pcps", dto);
}

export function getOrgList() {
  return axiosInstance.post("/super_admin/org_list", {});
}

export function getSpecialityList(dto) {
  return axiosInstance.post("/super_admin/speciality_master", dto);
}

export function addChatData(dto) {
  return axiosInstance.post("/chat/chat_datas", dto);
}