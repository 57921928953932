// Constans import
import { OK } from "../../resources/constants/ResponseCode";

// api import
import { getAllChats } from "../../services/apiServices/ChatsAPI";

// store imports
import {
  changeChatsFilterCount,
  changeChatsTableData,
  changeIsLoadingStatus,
  changeTotalRecords,
} from "../../store/ChatSlice";

// helper function import
import { appliedChatFiltersCount } from "../../utils/helperFunctions";

export async function ChatsFetch(dispatch, chatsPayload) {
  try {
    dispatch(changeIsLoadingStatus(true));
    const data = await getAllChats(chatsPayload);
    if (data?.status === OK) {
      dispatch(changeChatsTableData(data?.data?.rows));
      dispatch(changeTotalRecords(data?.data?.count));
      const count = await appliedChatFiltersCount(chatsPayload);
      dispatch(changeChatsFilterCount(count));
    }

    return !data?.status === OK;
    // adding ! to satisty isError flag to render something went wrong
  } catch (error) {
    return true;
    // returning true to render something went wrong page
    // empty catch block since error is already handled in interceptors
  } finally {
    dispatch(changeIsLoadingStatus(false));
  }
}
