import React, { useEffect, useState } from "react";
import { Button, Input, Modal, Skeleton, Typography, message } from "antd";
import { CopyOutlined } from "@ant-design/icons";

//styles import
import styles from "./Chats.module.scss";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeVideoLinkModalVisibility, updateVideoLinkById } from "../../store/ChatSlice";

// constant import
import languageConstant from "../../resources/constants/StringConstants";
import { OK } from "../../resources/constants/ResponseCode";

// api import
import { getVideoCallLink } from "../../services/apiServices/ChatsAPI";

// component import
import PlainSomethingWentWrong from "../errorPage/PlainSomethingWentWrong";

export default function VideoLinkModal() {
  const { visible, langKey, videoLink, chatSessionId } = useSelector(
    (store) => ({
      visible: store.chats.videoLinkModal.visible,
      langKey: store.config.lang,
      videoLink: store.chats.videoLinkModal.videoLink,
      chatSessionId: store.chats.chatSessionId,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const [localLinkCopy, setLocalLinkCopy] = useState(videoLink || "");
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const handleCancel = () => {
    dispatch(changeVideoLinkModalVisibility(false));
  };

  useEffect(() => {
    if (!localLinkCopy) {
      generateVideoCallLinkFunction();
    } else {
      setLoading(false);
    }
  }, []);

  const generateVideoCallLinkFunction = async () => {
    const isErrorFlag = await generateVideoCallLink();
    setIsError(isErrorFlag);
  };

  const generateVideoCallLink = async () => {
    try {
      setLoading(true);
      /* eslint-disable camelcase */
      const data = await getVideoCallLink({ session_id: chatSessionId });
      /* eslint-enable camelcase */

      if (data?.status === OK) {
        const dataCopy = data?.data?.video_call_link;
        setLocalLinkCopy(dataCopy);
        dispatch(
          updateVideoLinkById({
            id: chatSessionId,
            newLink: dataCopy,
          })
        );
      }

      return !(data?.status === OK); // to satisfy isError
    } catch (error) {
      setLocalLinkCopy("");

      return true; // to satisfy isError
      // empty catch block since error is already handled in interceptors
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={languageConstant[langKey].generateMeetingLink}
      centered
      open={visible}
      footer={null}
      width={1150}
      onCancel={handleCancel}
    >
      <hr />
      {chatSessionId && (
        <>
          <div>
            {loading ? (
              <div className={styles["chats-meeting-shimmer-container"]}>
                <Skeleton.Input
                  active
                  style={{
                    left: 0,
                    top: 0,
                    position: "absolute",
                    width: "100%",
                    height: "50px",
                  }}
                />
              </div>
            ) : isError ? (
              <PlainSomethingWentWrong refetch={generateVideoCallLinkFunction} />
            ) : (
              <Input
                className={styles["chats-meeting-modal-input"]}
                value={localLinkCopy}
                suffix={
                  <CopyOutlined
                    style={{ fontSize: "22px" }}
                    onClick={() => {
                      navigator.clipboard.writeText(localLinkCopy);
                      message.success({
                        content: languageConstant[langKey].linkCopiedToClipboard,
                        style: {
                          marginTop: "12vh",
                        },
                      });
                    }}
                  />
                }
              />
            )}
          </div>

          {!isError && (
            <div className={styles["chat-meeting-modal-button"]}>
              {loading ? (
                <Skeleton.Input style={{ height: 50, width: "90%" }} active />
              ) : (
                <Button
                  type="primary"
                  className="general-button-large"
                  onClick={() => {
                    generateVideoCallLink();
                  }}
                >
                  {languageConstant[langKey].generateNewLink}
                </Button>
              )}
            </div>
          )}
        </>
      )}

      {/* show missing session id */}
      {!chatSessionId && (
        <Typography className={styles["chats-id-missing"]}>
          {languageConstant[langKey].missingSessionId}
        </Typography>
      )}
    </Modal>
  );
}
