// Constans import
import { OK } from "../../../resources/constants/ResponseCode";

// api import

import { getSubmitterConsumerList } from "../../../services/apiServices/SubmitterConsumerAPI"; // eslint-disable-line max-len

// store import
import {
  changeSubmitterConsumerData,
  changeSubmitterConsumerInfiniteScrollLoading,
  changeSubmitterConsumerIsLoading,
  changeSubmitterConsumerMobileData,
  changeSubmitterConsumerTotalRecords,
  changeSubmitterConsumerFilterCount
} from "../../../store/SubmitterConsumerSlice";
import { appliedSubmitterConsumerFiltersCount } from "../../../utils/helperFunctions";

export async function FetchSubmitterConsumer(dispatch, usersPayload, newPage, mobileFetch) {
  try {
    if (mobileFetch) {
      dispatch(changeSubmitterConsumerInfiniteScrollLoading(true));
    } else {
      dispatch(changeSubmitterConsumerIsLoading(true));
    }
    const data = await getSubmitterConsumerList(
      mobileFetch ? { ...usersPayload, page: newPage } : usersPayload
    );
    if (data?.status === OK) {
      if (mobileFetch) {
        dispatch(changeSubmitterConsumerMobileData(data?.data?.rows));
      } else {
        dispatch(changeSubmitterConsumerData(data?.data?.rows));
      }
      dispatch(changeSubmitterConsumerTotalRecords(data?.data?.count));
      const count = await appliedSubmitterConsumerFiltersCount(usersPayload);
      dispatch(changeSubmitterConsumerFilterCount(count));
    }

    return !data?.status === OK;
    // adding ! to satisty isError flag to render something went wrong
  } catch (error) {
    return true;
    // returning true to render something went wrong page
  } finally {
    dispatch(changeSubmitterConsumerIsLoading(false));
    dispatch(changeSubmitterConsumerInfiniteScrollLoading(false));
  }
}
