// Constans import
import { OK } from "../../resources/constants/ResponseCode";

// api import
import { getOrganizationList } from "../../services/apiServices/OrganizationAPI"; // eslint-disable-line max-len

// store import
import {
  changeOrganizationData,
  changeOrganizationInfiniteScrollLoading,
  changeOrganizationIsLoading,
  changeOrganizationMobileData,
  changeOrganizationTotalRecords,
} from "../../store/OrganizationSlice";

export async function FetchOrganization(dispatch, organizationPayload, newPage, mobileFetch) {
  try {
    if (mobileFetch) {
      dispatch(changeOrganizationInfiniteScrollLoading(true));
    } else {
      dispatch(changeOrganizationIsLoading(true));
    }
    const data = await getOrganizationList(
      mobileFetch ? { ...organizationPayload, page: newPage } : organizationPayload
    );
    if (data?.status === OK) {
      if (mobileFetch) {
        dispatch(changeOrganizationMobileData(data?.data?.rows));
      } else {
        dispatch(changeOrganizationData(data?.data?.rows));
      }
      dispatch(changeOrganizationTotalRecords(data?.data?.count));
    }

    return !data?.status === OK;
    // adding ! to satisty isError flag to render something went wrong
  } catch (error) {
    return true;
    // returning true to render something went wrong page
  } finally {
    dispatch(changeOrganizationIsLoading(false));
    dispatch(changeOrganizationInfiniteScrollLoading(false));
  }
}
