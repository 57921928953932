import React from "react";
import { Button, Result } from "antd";
import PropTypes from "prop-types";

// constants import
import languageConstant from "../../resources/constants/StringConstants";

// store import
import { useSelector } from "react-redux";

export default function SomethingWentWrong({ refetch }) {
  const langKey = useSelector((store) => store.config.lang);

  return (
    <Result
      status="500"
      title="500"
      subTitle={languageConstant[langKey].serverErrorSomethingWentWrong}
      extra={
        refetch && (
          <Button type="primary" className="general-button" onClick={refetch}>
            {languageConstant[langKey].tryAgain}
          </Button>
        )
      }
    />
  );
}

SomethingWentWrong.propTypes = {
  refetch: PropTypes.func,
};
