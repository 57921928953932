import React from "react";
import { Avatar, Skeleton, Typography } from "antd";
import PropTypes from "prop-types";

// styles import
import styles from "../Users.module.scss";

// constants import
import defaultImg from "../../../resources/assets/defaultImg.svg";
import editIcon from "../../../resources/assets/edit.svg";
import languageConstant from "../../../resources/constants/StringConstants";

// store import
import { shallowEqual, useSelector } from "react-redux";

export default function ResponderSubmitterProviderCard({ staff, forceLoading }) {
  const { langKey, isLoading } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      isLoading: store.responderSubmitterProvider.isLoading,
    }),
    shallowEqual
  );

  return (
    <div className={styles["users-mobile-card-main-div"]}>
      <div className={styles["users-mobile-img-name-div"]}>
        {isLoading || forceLoading ? (
          // add size of image used below
          <Skeleton.Avatar active size={40} />
        ) : (
          <Avatar
            /* add size of the image used below */
            size={40}
            icon={
              <img
                className={styles["users-profile-pic"]}
                alt="PPic"
                src={staff["image_url"] || defaultImg}
              />
            }
          />
        )}
        <div className={styles["users-card-name-hospital-div"]}>
          {isLoading || forceLoading ? (
            <Skeleton.Input style={{ height: 16 }} active />
          ) : (
            <Typography className={styles["users-card-user-name"]}>
              {`${staff["first_name"]} ${staff["last_name"]}`.trim() ||
                languageConstant[langKey].noData}
            </Typography>
          )}
          {isLoading || forceLoading ? (
            <Skeleton.Input style={{ height: 12 }} active />
          ) : (
            <Typography className={styles["users-card-speciality"]}>
              {staff["spec_master.name"] || languageConstant[langKey].noData}
            </Typography>
          )}
        </div>
      </div>
      <div className={styles["users-email-div"]}>
        <div>
          <Typography className={styles["users-card-email-heading"]}>
            {languageConstant[langKey].email}
          </Typography>
          {isLoading || forceLoading ? (
            <Skeleton.Input style={{ height: 12 }} active />
          ) : (
            <Typography className={styles["users-card-email"]}>
              {staff["email"] || languageConstant[langKey].noData}
            </Typography>
          )}
        </div>
      </div>
      <div className={styles["users-card-phone-div"]}>
        <div>
          <Typography className={styles["users-card-phone-heading"]}>
            {languageConstant[langKey].phone}
          </Typography>
          {isLoading || forceLoading ? (
            <Skeleton.Input style={{ height: 12 }} active />
          ) : (
            <Typography className={styles["users-card-phone"]}>
              {staff["mobile"] || languageConstant[langKey].noData}
            </Typography>
          )}
        </div>
        {isLoading || forceLoading ? (
          <Skeleton.Avatar active size={20} />
        ) : (
          <img className={styles["users-edit-icon"]} alt="eicon" src={editIcon} />
        )}
      </div>
    </div>
  );
}

ResponderSubmitterProviderCard.propTypes = {
  /* eslint-disable camelcase */
  staff: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    image_url: PropTypes.string,
    "spec_master.name": PropTypes.string,
    email: PropTypes.string,
    mobile: PropTypes.string,
  }),
  forceLoading: PropTypes.bool.isRequired,
  /* eslint-enable camelcase */
};
