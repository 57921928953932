import React, { useEffect, useState } from "react";
import { Pagination, Skeleton, Typography } from "antd";

// styles import
import styles from "./Chats.module.scss";

// redux store
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeIsErrorStatus, changePageNumber } from "../../store/ChatSlice";

// api import
import { ChatsFetch } from "./ChatsFetch";

// constant import
import languageConstant from "../../resources/constants/StringConstants";

export default function ChatsPagination() {
  const { pageNo, pageLimit, totalRecords, body, isLoading } = useSelector(
    (store) => ({
      pageNo: store.chats.chatsPayload.page,
      pageLimit: store.chats.chatsPayload.limit,
      totalRecords: store.chats.totalRecords,
      body: store.chats.chatsPayload,
      isLoading: store.chats.isLoading,
    }),
    shallowEqual
  );
  const langKey = useSelector((store) => store.config.lang);
  const startIndex = (pageNo - 1) * pageLimit + 1;
  const endIndex = Math.min(pageNo * pageLimit, totalRecords);
  const dispatch = useDispatch();
  const handlePageChange = (page) => {
    dispatch(changePageNumber(page));
  };
  const [componentRendered, setComponentRendered] = useState(false);

  useEffect(() => {
    if (componentRendered) {
      chatsFetchFuncton();
    }
    setComponentRendered(true);
  }, [pageNo]);

  const chatsFetchFuncton = async () => {
    const isErrorFlag = await ChatsFetch(dispatch, body);
    dispatch(changeIsErrorStatus(isErrorFlag));
  };

  return (
    <div className={styles["chats-pagination-container"]}>
      <Typography className={styles["chats-entries-typo"]}>
        {`${languageConstant[langKey].showing} 
        ${isLoading ? 0 : startIndex} ${languageConstant[langKey].to} 
        ${isLoading ? 0 : endIndex} ${languageConstant[langKey].of} ${isLoading ? 0 : totalRecords} 
        ${languageConstant[langKey].entries}`}
      </Typography>
      {isLoading ? (
        <Skeleton.Input
          active
          size="default"
          className={styles["chats-pagination"]}
          style={{ width: "200px" }}
        />
      ) : (
        <Pagination
          className={styles["chats-pagination"]}
          current={pageNo}
          total={totalRecords}
          pageSize={pageLimit}
          onChange={handlePageChange}
          showSizeChanger={false}
        />
      )}
    </div>
  );
}
