import React from "react";
import { shallowEqual, useSelector } from "react-redux";

export default function ResponderSubmitterProviderModal() {
  const { modalDto } = useSelector(
    (store) => ({
      modalDto: store.responderSubmitterProvider.modalDto,
    }),
    shallowEqual
  );

  return (
    <div>
      Hello from Responder Submitter Provider
      <div>
        name : {modalDto.name} Age: {modalDto.age}
      </div>
    </div>
  );
}
