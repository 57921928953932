import React, { useEffect } from "react";

// styles import
import styles from "../Users.module.scss";

// components import
import SubmitterHeader from "./SubmitterHeader";
import SubmitterTable from "./SubmitterTable";
import SubmitterPagination from "./SubmitterPagination";
import SubmitterMobileRows from "./SubmitterMobileRows";

// store import
import { useDispatch } from "react-redux";
import {
  changeSubmitterData,
  resetSubmitterPayload,
} from "../../../store/SubmitterSlice";

// constants import
import { DummyTableData, constantSubmitterPayload } from "../../../utils/ConstantObjects";

export default function Submitter() {
  const isMobile = window.innerWidth < 768; // threshold for mobile devices

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(changeSubmitterData(DummyTableData));
      dispatch(resetSubmitterPayload(constantSubmitterPayload));
    };
  }, []);

  return (
    <>
      <SubmitterHeader />
      {!isMobile && (
        <div className={styles["users-table-visibility"]}>
          <SubmitterTable />
        </div>
      )}
      {isMobile && (
        <div className={styles["users-card-visibility"]}>
          <SubmitterMobileRows />
        </div>
      )}
      {!isMobile && <SubmitterPagination />}
    </>
  );
}
