export const CurrentConfig = {
  endpointURL: {
    development: "https://staging-test.afya.chat/",
    uat: "https://uat-test.afya.chat/",
    production: "https://backend.test-afya.afya.chat/",
  }, 
  adminRedesignApiURL: {
    development: "",
    uat: "https://uat-admin-redesign-api-x72hlbrgrq-uw.a.run.app/",
    production: "https://admin-redesign-api-x72hlbrgrq-uw.a.run.app/",
  },
  mestabaseApiURL: {
    metabaseAPI: "https://staging-metabase-api-x72hlbrgrq-uw.a.run.app/",
    localMetabase: "http://localhost:5001/",
  },
};

export const APP_VERSION_NUMBER = "V: 02.08.003";
