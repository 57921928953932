import { createSlice } from "@reduxjs/toolkit";

// constants import
import { DummyTableData, constantSubmitterPayload } from "../utils/ConstantObjects";

const SubmitterSlice = createSlice({
  name: "submitter",
  initialState: {
    isLoading: true,
    isError: false,
    infiniteScrollLoading: false,
    userData: DummyTableData,
    totalRecords: 0,
    payload: constantSubmitterPayload,
    modalDto: {
      name: "",
      age: 21,
    },
    filterDrawer: {
      visible: false,
      filterCount: 0,
      filterApplied: false,
    },
  },
  reducers: {
    changeSubmitterIsLoading: (state, action) => {
      state.isLoading = action.payload;
    },
    changeSubmitterIsError: (state, action) => {
      state.isError = action.payload;
    },
    changeSubmitterInfiniteScrollLoading: (state, action) => {
      state.infiniteScrollLoading = action.payload;
    },
    changeSubmitterData: (state, action) => {
      state.userData = action.payload;
    },
    changeSubmitterMobileData: (state, action) => {
      const payload = action.payload || {};

      const newUserData = [...state.userData, ...payload];

      state.userData = newUserData;
    },
    changeSubmitterTotalRecords: (state, action) => {
      state.totalRecords = action.payload;
    },
    changeSubmitterPageNo: (state, action) => {
      state.payload.page = action.payload;
    },
    changeSubmitterPageLimit: (state, action) => {
      state.payload.limit = action.payload;
    },
    resetSubmitterPayload: (state, action) => {
      state.payload = action.payload;
    },
    updateSubmitterModalDto: (state, action) => {
      const { newName, newAge } = action.payload;
      state.modalDto = {
        name: newName,
        age: newAge,
      };
    },
    changeFilterDrawerVisibility: (state, action) => {
      state.filterDrawer.visible = action.payload;
    },
    changeSubmitterFilterCount: (state, action) => {
      state.filterDrawer.filterCount = action.payload;
    },
    changeSubmitterFilterAppliedStatus: (state, action) => {
      state.filterDrawer.filterApplied = action.payload;
    },
    changeFilterSubmitterName: (state, action) => {
      /* eslint-disable camelcase */
      state.payload.sub_name = action.payload;
    },
    changeFilterNPI: (state, action) => {
      state.payload.npi = action.payload;
    },
    changeFilterMedicalGroupName: (state, action) => {
      state.payload.medical_group = action.payload;
    },
    resetPayloadFilter: (state, action) => {
      state.payload = action.payload;
    },
    resetSubmitterPayloadOnCancelAndFilterNotApplied: (state) => {
      /* eslint-disable camelcase */
      state.payload = {
        ...state.payload,
        sub_name: "",
        npi: "",
        medical_group: "",
      };
    },
  },
});

export const {
  changeSubmitterIsLoading,
  changeSubmitterIsError,
  changeSubmitterInfiniteScrollLoading,
  changeSubmitterData,
  changeSubmitterMobileData,
  changeSubmitterTotalRecords,
  changeSubmitterPageNo,
  changeSubmitterPageLimit,
  resetSubmitterPayload,
  updateSubmitterModalDto,
  changeFilterDrawerVisibility,
  changeFilterSubmitterName,
  changeFilterMedicalGroupName,
  changeFilterNPI,
  resetSelectedDrawerFilter,
  resetPayloadFilter,
  changeSubmitterFilterCount,
  changeSubmitterFilterAppliedStatus,
  resetSubmitterPayloadOnCancelAndFilterNotApplied,
} = SubmitterSlice.actions;

export default SubmitterSlice.reducer;
