// Constanst import
import { OK } from "../../../resources/constants/ResponseCode";

// api import

import { getSubmitterList } from "../../../services/apiServices/SubmitterAPI"; // eslint-disable-line max-len

// store import
import {
  changeSubmitterData,
  changeSubmitterFilterCount,
  changeSubmitterInfiniteScrollLoading,
  changeSubmitterIsLoading,
  changeSubmitterMobileData,
  changeSubmitterTotalRecords,
} from "../../../store/SubmitterSlice";
import { appliedSubmitterFiltersCount } from "../../../utils/helperFunctions";

export async function FetchSubmitter(dispatch, usersPayload, newPage, mobileFetch) {
  try {
    if (mobileFetch) {
      dispatch(changeSubmitterInfiniteScrollLoading(true));
    } else {
      dispatch(changeSubmitterIsLoading(true));
    }
    const data = await getSubmitterList(
      mobileFetch ? { ...usersPayload, page: newPage } : usersPayload
    );
    if (data?.status === OK) {
      if (mobileFetch) {
        dispatch(changeSubmitterMobileData(data?.data?.rows));
      } else {
        dispatch(changeSubmitterData(data?.data?.rows));
      }
      dispatch(changeSubmitterTotalRecords(data?.data?.count));
      const count = await appliedSubmitterFiltersCount(usersPayload);
      dispatch(changeSubmitterFilterCount(count));
    }

    return !data?.status === OK;
    // adding ! to satisty isError flag to render something went wrong
  } catch (error) {
    return true;
    // returning true to render something went wrong page
  } finally {
    dispatch(changeSubmitterIsLoading(false));
    dispatch(changeSubmitterInfiniteScrollLoading(false));
  }
}
