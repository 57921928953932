import { React, useState, useEffect } from "react";
import { Spin } from "antd";

// Constants import
import styles from "./Insights.module.scss";
const contentStyle = {
  padding: 50,
  borderRadius: 4,
};

const content = <div style={contentStyle} />;

// API import
import { getDashboard, wakeupMetabase } from "../../services/apiServices/MetabaseAPI";
import SomethingWentWrong from "../errorPage/SomethingWentWrong";

const fetchEmbedUrl = async () => {
  const orgId = sessionStorage.getItem("organizationId");
  if (orgId == null) {
    return null;
  }

  try {
    const response = await getDashboard(
      orgId,
      sessionStorage.getItem("authToken"),
      4,
      sessionStorage.getItem("role")
    );

    return response.data.embed_url;
  } catch (error) {
    console.log("Error fetching embed url: ", error);

    return null;
  }
};

export default function Insights() {
  const [embedUrl, setEmbedUrl] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  // fetches dashboard from flask
  let intervalId; 
  const loadEmbedUrl = async () => {
    try {
      const url = await fetchEmbedUrl();
      setEmbedUrl(url);
      intervalId = setInterval(initiateHealthCheck, 4000);
    }
    catch (error) {
      setError(true);
      clearInterval(intervalId); // Stop the interval when an error occurs
    }
  };
  
  const initiateHealthCheck = async () => {
    try {
      const isUp = await wakeupMetabase();
      if (isUp) {
        // wait 5 more seconds to ensure Metabase is fully up
        setTimeout(() => {
          setLoading(false);
        }, 9000);
        clearInterval(intervalId);
      }
    } catch (error) {
      console.log("Retrying to check Metabase health...");
      clearInterval(intervalId); // Stop the interval when an error occurs
    }
  };
  
  useEffect(() => {
    loadEmbedUrl();
  }, []);

  if (error) return <SomethingWentWrong refetch={loadEmbedUrl} />;

  return (
    <div className={styles["insights-main-container"]}>
      {loading ? (
        <Spin tip="Loading" size="large">
          {content}
        </Spin>
      ) : (
        <iframe src={embedUrl} frameBorder={0} width={"100%"} height={"100%"} />
      )}
    </div>
  );
}