import React, { useEffect, useState } from "react";
import { Select, Typography } from "antd";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeSubmitterIsError,
  changeSubmitterPageLimit,
  changeSubmitterPageNo,
} from "../../../store/SubmitterSlice";

// constants import
import { PageSizeOptions } from "../../../utils/ConstantObjects";
import languageConstant from "../../../resources/constants/StringConstants";

// styles import
import styles from "../Users.module.scss";

// api function import
import { FetchSubmitter } from "./FetchSubmitter";

export default function SubmitterSizeChange() {
  const { langKey, pageLimit, body, apiLoading } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      pageLimit: store.submitter.payload.limit,
      body: store.submitter.payload,
      apiLoading: store.submitter.isLoading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [componentRendered, setComponentRendered] = useState(false);

  const handleSizeChange = (value) => {
    dispatch(changeSubmitterPageLimit(value));
    dispatch(changeSubmitterPageNo(1));
  };

  useEffect(() => {
    if (componentRendered) {
      fetchSubmitterFunction();
    }
    setComponentRendered(true);
  }, [pageLimit]);

  const fetchSubmitterFunction = async () => {
    const isErrorFlag = await FetchSubmitter(dispatch, body, null, false);
    dispatch(changeSubmitterIsError(isErrorFlag));
  };

  return (
    <div className={styles["users-size-change-container"]}>
      <Typography className={styles["users-entries-typo"]}>
        {languageConstant[langKey].show}
      </Typography>
      <Select
        defaultValue="10"
        onChange={handleSizeChange}
        options={PageSizeOptions}
        disabled={apiLoading}
      />
      <Typography className={styles["users-entries-typo"]}>
        {languageConstant[langKey].entries}
      </Typography>
    </div>
  );
}
