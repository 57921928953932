import React, { useEffect, useState } from "react";
import { Select, Typography } from "antd";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeOrganizationIsError,
  changeOrganizationPageLimit,
  changeOrganizationPageNo,
} from "../../store/OrganizationSlice";

// constants import
import { PageSizeOptions } from "../../utils/ConstantObjects";
import languageConstant from "../../resources/constants/StringConstants";

// styles import
import styles from "./Organization.module.scss";

// api function import
import { FetchOrganization } from "./FetchOrganization";

export default function OrganizationSizeChange() {
  const { langKey, pageLimit, body, apiLoading } = useSelector(
    (store) => ({
      langKey: store.config.lang,
      pageLimit: store.organization.payload.limit,
      body: store.organization.payload,
      apiLoading: store.organization.isLoading,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();
  const [componentRendered, setComponentRendered] = useState(false);

  const handleSizeChange = (value) => {
    dispatch(changeOrganizationPageLimit(value));
    dispatch(changeOrganizationPageNo(1));
  };

  useEffect(() => {
    if (componentRendered) {
      fetchOrganizationFunction();
    }
    setComponentRendered(true);
  }, [pageLimit]);

  const fetchOrganizationFunction = async () => {
    const isErrorFlag = await FetchOrganization(dispatch, body, null, false);
    dispatch(changeOrganizationIsError(isErrorFlag));
  };

  return (
    <div className={styles["organization-size-change-container"]}>
      <Typography className={styles["organization-entries-typo"]}>
        {languageConstant[langKey].show}
      </Typography>
      <Select
        defaultValue="10"
        onChange={handleSizeChange}
        options={PageSizeOptions}
        disabled={apiLoading}
      />
      <Typography className={styles["organization-entries-typo"]}>
        {languageConstant[langKey].entries}
      </Typography>
    </div>
  );
}
