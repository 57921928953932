import React, { useEffect, useState } from "react";
import { List, Modal, Skeleton, Typography } from "antd";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeChatsModalVisibility } from "../../store/ChatSlice";

// styles import
import styles from "./Chats.module.scss";

// constants import
import languageConstant from "../../resources/constants/StringConstants";
import {
  DummyShimmerListData,
  constantDateFormat,
  constantTimeFormat,
} from "../../utils/ConstantObjects";
import { OK } from "../../resources/constants/ResponseCode";

// component import
import ChatsModalComponent from "./ChatsModalComponent";

// api import
import { getChatsByChatId } from "../../services/apiServices/ChatsAPI";

// Date api
import { DateTime } from "luxon";

// component import
import PlainSomethingWentWrong from "../errorPage/PlainSomethingWentWrong";

export default function ChatsModal() {
  const { visible, chatSessionId, langKey } = useSelector(
    (store) => ({
      visible: store.chats.chatsModal.visible,
      chatSessionId: store.chats.chatSessionId,
      langKey: store.config.lang,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(changeChatsModalVisibility(false));
  };
  const [loading, setLoading] = useState(true);
  const [isError, setIsError] = useState(false);
  const [chatData, setChatData] = useState([]);
  let previousFormattedDate = null;
  useEffect(() => {
    if (chatSessionId) {
      fetchChatsFunction();
    }
  }, []);

  const fetchChatsFunction = async () => {
    const isErrorFlag = await fetchChatById();
    setIsError(isErrorFlag);
  };
  const fetchChatById = async () => {
    try {
      setLoading(true);
      /* eslint-disable camelcase */
      const data = await getChatsByChatId({ session_id: chatSessionId });
      /* eslint-enable camelcase */
      if (data?.status === OK) {
        setChatData(data?.data);
      }

      return !(data?.status === OK);
    } catch (error) {
      setChatData([]);

      return true; //to satisfy isError
      // empty catch block since error is already handled in interceptors
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal
      title={languageConstant[langKey].chatDialogue}
      centered
      open={visible}
      footer={null}
      width={1150}
      onCancel={handleCancel}
    >
      <hr />

      {chatSessionId ? (
        loading ? (
          <List
            dataSource={DummyShimmerListData}
            renderItem={() => (
              <List.Item>
                <Skeleton loading={true} active></Skeleton>
              </List.Item>
            )}
          />
        ) : isError ? (
          <PlainSomethingWentWrong refetch={fetchChatsFunction} />
        ) : (
          <div className={styles["chats-modal-main-container"]}>
            {chatData
              .slice()
              .reverse()
              .map((item) => {
                const formattedDate = DateTime.fromISO(item.createdAt, {
                  zone: "America/Los_Angeles",
                }).toLocaleString(constantDateFormat);
                const formattedTime = DateTime.fromISO(item.createdAt, {
                  zone: "America/Los_Angeles",
                }).toLocaleString(constantTimeFormat);

                if (formattedDate !== previousFormattedDate) {
                  previousFormattedDate = formattedDate;

                  return (
                    <div key={`chats-container-${item.id}`}>
                      {formattedDate && (
                        <div
                          className={styles["chats-modal-date-stamp-container"]}
                          key={`time-stamp-${item.id}`}
                        >
                          <Typography className={styles["chats-modal-date-stamp"]}>
                            {formattedDate}
                          </Typography>
                        </div>
                      )}
                      <ChatsModalComponent
                        key={item.id}
                        name={item.from_name || ""}
                        message={item.message || ""}
                        time={formattedTime || ""}
                      />
                    </div>
                  );
                } else {
                  return (
                    <ChatsModalComponent
                      key={item.id}
                      name={item.from_name || ""}
                      message={item.message || ""}
                      time={formattedTime || ""}
                    />
                  );
                }
              })}
          </div>
        )
      ) : (
        <Typography className={styles["chats-id-missing"]}>
          {languageConstant[langKey].missingChatId}
        </Typography>
      )}
    </Modal>
  );
}
