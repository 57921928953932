import React, { useEffect, useState } from "react";
import { Avatar, Badge, Select, Tooltip, Typography } from "antd";

// styles import
import styles from "./Chats.module.scss";

// store imports
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import {
  changeFilterDrawerVisibility,
  changeIsErrorStatus,
  changePageLimit,
  changePageNumber,
} from "../../store/ChatSlice";

// api import
import { ChatsFetch } from "./ChatsFetch";

// constants import
import languageConstant from "../../resources/constants/StringConstants";
import filterIcon from "../../resources/assets/filter.svg";
import { PageSizeOptions } from "../../utils/ConstantObjects";

// component import
import FilterDrawer from "./FilterDrawer";

export default function ChatsFilters() {
  const langKey = useSelector((store) => store.config.lang);
  const dispatch = useDispatch();
  const handleSizeChange = (value) => {
    dispatch(changePageLimit(value));
    dispatch(changePageNumber(1));
  };

  const { pageLimit, body, renderFilterDrawer, filterCount, apiLoading,
    //  chatsIsError 
  } =
    useSelector(
      (store) => ({
        pageLimit: store.chats.chatsPayload.limit,
        body: store.chats.chatsPayload,
        renderFilterDrawer: store.chats.filterDrawer.visible,
        filterCount: store.chats.filterDrawer.filterCount,
        apiLoading: store.chats.isLoading,
        chatsIsError: store.chats.isError,
      }),
      shallowEqual
    );

  const [componentRendered, setComponentRendered] = useState(false);

  useEffect(() => {
    if (componentRendered) {
      chatsFetchFuncton();
    }
    setComponentRendered(true);
  }, [pageLimit]);

  const chatsFetchFuncton = async () => {
    const isErrorFlag = await ChatsFetch(dispatch, body);
    dispatch(changeIsErrorStatus(isErrorFlag));
  };

  return (
    <div className={styles["chats-filters-main-container"]}>
      <div className={styles["chats-size-change-container"]}>
        <Typography className={styles["chats-entries-typo"]}>
          {languageConstant[langKey].show}
        </Typography>
        <Select
          defaultValue="10"
          onChange={handleSizeChange}
          options={PageSizeOptions}
          disabled={apiLoading}
        />
        <Typography className={styles["chats-entries-typo"]}>
          {languageConstant[langKey].entries}
        </Typography>
      </div>
      <div className={styles["chats-buttons-container"]}>
        {/* <Button type="primary" className="general-button" disabled={chatsIsError}>
          {languageConstant[langKey].downloadCSV}
        </Button> */}
        <Badge
          count={filterCount || 0}
          overflowCount={10}
          onClick={() => {
            dispatch(changeFilterDrawerVisibility(true));
          }}
        >
          <Tooltip title={languageConstant[langKey].filterTableRecords}>
            <Avatar shape="round" size="default" className={styles["chats-avatar"]}>
              <img
                src={filterIcon}
                className={styles["chats-filter-image"]}
                style={{
                  maxWidth: "16px",
                  maxHeight: "16px",
                  marginTop: "20%",
                  userSelect: "none",
                }}
                alt="filter"
              />
            </Avatar>
          </Tooltip>
        </Badge>
      </div>
      {renderFilterDrawer && <FilterDrawer />}
    </div>
  );
}
