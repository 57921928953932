import React, { useEffect } from "react";

// styles import
import styles from "../Users.module.scss";

// components import
import SubmitterConsumerHeader from "./SubmitterConsumerHeader";
import SubmitterConsumerTable from "./SubmitterConsumerTable";
import SubmitterConsumerPagination from "./SubmitterConsumerPagination";
import SubmitterConsumerMobileRows from "./SubmitterConsumerMobileRows";

// store import
import { useDispatch } from "react-redux";
import {
  changeSubmitterConsumerData,
  resetSubmitterConsumerPayload,
} from "../../../store/SubmitterConsumerSlice";

// constants import
import { DummyTableData, constantSubmitterConsumerPayload } from "../../../utils/ConstantObjects";

export default function SubmitterConsumer() {
  const isMobile = window.innerWidth < 768; // threshold for mobile devices

  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      dispatch(changeSubmitterConsumerData(DummyTableData));
      dispatch(resetSubmitterConsumerPayload(constantSubmitterConsumerPayload));
    };
  }, []);

  return (
    <>
      <SubmitterConsumerHeader />
      {!isMobile && (
        <div className={styles["users-table-visibility"]}>
          <SubmitterConsumerTable />
        </div>
      )}
      {isMobile && (
        <div className={styles["users-card-visibility"]}>
          <SubmitterConsumerMobileRows />
        </div>
      )}
      {!isMobile && <SubmitterConsumerPagination />}
    </>
  );
}
