/**
 * @descriptions success resposne
 */

export const OK = 200;

/**
 * @descriptions failure resposne
 */

export const UNAUTHORIZED = 401;
export const SOMETHING_WENT_WRONG = 701;
export const USER_ALREADY_EXIST = 702;
export const WRONG_CREDENTIALS = 703;
export const USER_DOES_NOT_EXIST = 704;
