import React, { useState } from "react";
import { Button, Divider, Dropdown, Skeleton, Typography } from "antd";

// react router import
import { useLocation, useNavigate } from "react-router-dom";

// styles import
import styles from "./NavigationMenu.module.scss";

//constants import
import afyaLogo from "../../resources/assets/afya-icon.svg";
import burgerMenu from "../../resources/assets/burgerMenu.svg";
import cancelIcon from "../../resources/assets/cancelIcon.svg";
import languageConstant from "../../resources/constants/StringConstants";
import {
  PATH_LOGIN,
  PATH_ORGANIZATION,
  PATH_REPORTS,
  PATH_INSIGHTS,
  PATH_CHATS,
  // PATH_SYSTEM_SETUP,
  // PATH_TOOLS,
  PATH_USERS,
} from "../../resources/constants/RoutesPathConstants";
import defaultImg from "../../resources/assets/defaultImg.svg";
import { OK } from "../../resources/constants/ResponseCode";
import { APP_VERSION_NUMBER } from "../../services/http/Config";

// store import
import { useSelector, useDispatch } from "react-redux";
import { changeMobileMenuStatus } from "../../store/ConfigSlice";

// icon import
import { LogoutOutlined } from "@ant-design/icons";
import { logoutUserWithId } from "../../services/apiServices/AuthAPI";

export default function NavigationMenu() {
  const location = useLocation();
  const langKey = useSelector((store) => store.config.lang);
  const MobileMenuOpenStatus = useSelector((store) => store.config.isMobileMenuOpen);
  const dispatch = useDispatch();
  const changeMobileMenuFlag = () => {
    dispatch(changeMobileMenuStatus(!MobileMenuOpenStatus));
  };
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const navMenuItems = [
    {
      id: 101,
      name: languageConstant[langKey].insights,
      path: PATH_INSIGHTS,
    },
    {
      id: 102,
      name: languageConstant[langKey].users,
      path: PATH_USERS,
    },
    {
      id: 103,
      name: languageConstant[langKey].organization,
      path: PATH_ORGANIZATION,
      visible: sessionStorage.getItem("role") === "Super Admin", 
    },
    {
      id: 104,
      name: languageConstant[langKey].chatSessions,
      path: PATH_CHATS,
    },
    {
      id: 105,
      name: languageConstant[langKey].reports,
      path: PATH_REPORTS,
    },
    // {
    //   id: 106,
    //   name: languageConstant[langKey].systemSetup,
    //   path: PATH_SYSTEM_SETUP,
    // },
    // {
    //   id: 108,
    //   name: languageConstant[langKey].tools,
    //   path: PATH_TOOLS,
    // },
  ]; 
  const firstName = sessionStorage.getItem("firstName")
    ? sessionStorage.getItem("firstName").trim()
    : "";
  const lastName = sessionStorage.getItem("lastName")
    ? sessionStorage.getItem("lastName").trim()
    : "";
  const userName = firstName || lastName ? `${firstName} ${lastName}`.trim() : "user-name";
  const userEmail = sessionStorage.getItem("userEmail") || "email-id";
  const items = [
    {
      key: "0",
      label: (
        <div className={styles["nav-menu-drop-down-profil-div"]}>
          <img className={styles["nav-menu-profile-pic"]} alt="PPic" src={defaultImg} />
          <div>
            {isLoading || userName === "user-name" ? (
              <Skeleton.Input
                className={styles["nav-menu-drop-down-name"]}
                style={{ height: 15 }}
                active
              />
            ) : (
              <Typography className={styles["nav-menu-drop-down-name"]}>{userName}</Typography>
            )}

            {isLoading || userEmail === "email-id" ? (
              <Skeleton.Input
                className={styles["nav-menu-drop-down-email"]}
                style={{ height: 12 }}
                active
              />
            ) : (
              <Typography className={styles["nav-menu-drop-down-email"]}>{userEmail}</Typography>
            )}
          </div>
        </div>
      ),
      disabled: true,
    },

    {
      type: "divider",
    },
    {
      key: "2",
      label: (
        <div className={styles["nav-menu-logout-div"]}>
          <div className={styles["nav-menu-logout-button-version-div"]}>
            <Button
              type="primary"
              className="general-button"
              icon={<LogoutOutlined />}
              onClick={async () => {
                const isErrorFlag = await performAppLogout();
                setIsError(isErrorFlag);
              }}
              loading={isLoading}
            >
              {isError ? languageConstant[langKey].tryAgain : languageConstant[langKey].logout}
            </Button>
            <Typography className={styles["nav-menu-version-number"]}>
              {APP_VERSION_NUMBER}
            </Typography>
          </div>
        </div>
      ),
      disabled: true,
    },
  ];

  const performAppLogout = async () => {
    try {
      setIsLoading(true);
      const userId = sessionStorage.getItem("userId");
      const data = await logoutUserWithId({ id: userId });
      if (data?.status === OK) {
        window.location.href = PATH_LOGIN;
        sessionStorage.clear();
      }

      return !data?.status === OK; // adding ! to satisty isError flag
    } catch (error) {
      return true; // returning true to render something went wrong page
      // empty catch block since error is already handled in interceptors
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div
      className={`${styles["nav-menu-main-container"]} 
    ${MobileMenuOpenStatus && styles["nav-menu-main-container-active"]}`}
    >
      <div className={styles["nav-menu-nav-container"]}>
        <img
          className={styles["nav-menu-logo"]}
          src={afyaLogo}
          alt="AfyaLogo"
          onClick={() => {
            navigate(PATH_INSIGHTS);
            dispatch(changeMobileMenuStatus(false));
          }}
        />

        <div className={styles["nav-menu-options-web"]}>
          {navMenuItems.filter((item) => item.visible !== false)?.map((navItem) => (
            <Button
              type="primary"
              className={`${styles["nav-menu-rounded-buttons"]} 
          ${navItem.path === location.pathname && styles["nav-menu-active-button-color"]}`}
              key={navItem.id}
              onClick={() => {
                navigate(navItem?.path);
              }}
            >
              {navItem.name}
            </Button>
          ))}
        </div>
        <Dropdown menu={{ items }} placement="bottomRight" arrow>
          <img className={styles["nav-menu-profile-pic"]} alt="PPic" src={defaultImg} />
        </Dropdown>
        <img
          className={styles["nav-menu-dynamic-menu-icon"]}
          src={MobileMenuOpenStatus ? cancelIcon : burgerMenu}
          alt="MenuIcon"
          onClick={changeMobileMenuFlag}
        />
      </div>
      <div
        className={`${styles["nav-menu-options-mobile"]} 
    ${MobileMenuOpenStatus && styles["nav-menu-options-mobile-active"]}`}
      >
        <div className={styles["nav-menu-options-container"]}>
          {navMenuItems.filter((item) => item.visible !== false)?.map((navItem) => (
            <Button
              type="primary"
              className={`${styles["nav-menu-rounded-buttons"]} 
            ${navItem?.path === location.pathname && styles["nav-menu-active-button-color"]}`}
              key={navItem?.id}
              onClick={() => {
                navigate(navItem?.path);
              }}
            >
              {navItem?.name}
            </Button>
          ))}
          <div className={styles["nav-menu-mobile-logout-main-div"]}>
            <Divider style={{ marginTop: "-4%" }} />
            <div className={styles["nav-menu-mobile-loutout-version-button-div"]}>
              <Typography className={styles["nav-menu-version-number"]}>
                {APP_VERSION_NUMBER}
              </Typography>
              <Button
                type="primary"
                className={styles["nav-menu-logout-button"]}
                icon={<LogoutOutlined />}
                onClick={async () => {
                  const isErrorFlag = await performAppLogout();
                  setIsError(isErrorFlag);
                }}
                loading={isLoading}
              >
                {isError ? languageConstant[langKey].tryAgain : languageConstant[langKey].logout}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
