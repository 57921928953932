import React, { useEffect, useState } from "react";
import { Button, Modal, Select, Skeleton, Typography, message } from "antd";

// store import
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { changeRemoveResponderModalVisibility } from "../../store/ChatSlice";

// constant import
import languageConstant from "../../resources/constants/StringConstants";
import { OK } from "../../resources/constants/ResponseCode";
import { ACTIONS, DATATYPES } from "../../utils/ConstantObjects";

// styles import
import styles from "./Chats.module.scss";

// api import
import {
  addChatData,
  addRemoveUserFromSession,
  getSessionDetails,
} from "../../services/apiServices/ChatsAPI";

// component import
import PlainSomethingWentWrong from "../errorPage/PlainSomethingWentWrong";

export default function RemoveResponderModal() {
  const { visible, langKey, chatSessionId } = useSelector(
    (store) => ({
      visible: store.chats.removeResponderModal.visible,
      langKey: store.config.lang,
      chatSessionId: store.chats.chatSessionId,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();
  const handleCancel = () => {
    dispatch(changeRemoveResponderModalVisibility(false));
  };
  const [loading, setLoading] = useState(true);
  const [newResponderNotAddedFlag, setNewResponderNotAddedFlag] = useState(false);
  const [sessionMembersDetail, setSessionMembersDetail] = useState([]);
  const [selectedUserId, setSelectedUserId] = useState(null);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    if (chatSessionId) {
      getSessionMembersDetailsFunction();
    }
  }, []);

  const getSessionMembersDetailsFunction = async () => {
    const isErrorFlag = await getSessionMembersDetails();
    setIsError(isErrorFlag);
  };

  const getSessionMembersDetails = async () => {
    try {
      setLoading(true);
      setNewResponderNotAddedFlag(false);
      /* eslint-disable camelcase */
      const data = await getSessionDetails({ session_id: chatSessionId });
      /* eslint-enable camelcase */
      if (data?.status === OK) {
        if (data?.data["member_details"].length === 3) {
          setNewResponderNotAddedFlag(true);
        } else {
          setSessionMembersDetail(
            data?.data["member_details"].filter((item) => {
              // Check if the user_id is not equal to the owner1, owner2, or patient user_id
              return (
                item["user_id"] !== data.data["owner1.user_id"] &&
                item["user_id"] !== data.data["owner2.user_id"] &&
                item["user_id"] !== data.data["patient.user_id"]
              );
            }) || []
          );
        }
      }

      return !(data?.status === OK); // to satisfy isError flag
    } catch (error) {
      setSessionMembersDetail([]);

      return true; // to satisfy isError
      // empty catch block since error is already handled in interceptors
    } finally {
      setLoading(false);
    }
  };

  const removeUserFromSession = async () => {
    try {
      if (!selectedUserId) {
        message.error({
          content: languageConstant[langKey].pleaseChooseAProvider,
          style: {
            marginTop: "12vh",
          },
        });

        return;
      }
      setLoading(true);
      /* eslint-disable camelcase */
      const payload = {
        action: ACTIONS.REMOVE,
        session_id: chatSessionId,
        user_id: selectedUserId,
      };
      /* eslint-enable camelcase */
      const data = await addRemoveUserFromSession(payload);
      if (data?.status === OK) {
        const selectedProviderName = `${data?.data["first_name"]} ${data?.data["last_name"]}`;
        const bannerStatus = await addChatBanner(selectedProviderName);
        if (bannerStatus) {
          handleCancel();
          message.success({
            content: languageConstant[langKey].userRemovedFromChatSession,
            style: {
              marginTop: "12vh",
            },
          });
          setSelectedUserId(null);
          setSessionMembersDetail(
            sessionMembersDetail.filter((item) => {
              return item["user_id"] !== selectedUserId;
            }) || []
          );
        } else {
          message.error({
            content: languageConstant[langKey].userRemovedButFailedToAddBanner,
            style: {
              marginTop: "12vh",
            },
          });
        }
      } else {
        message.error({
          content: languageConstant[langKey].failedToRemoveUserFromSession,
          style: {
            marginTop: "12vh",
          },
        });
      }
    } catch (error) {
      // empty catch block since error is already handled in interceptors
    } finally {
      setLoading(false);
    }
  };

  const addChatBanner = async (selectedProviderName) => {
    try {
      const fromName = `${sessionStorage.getItem("firstName")} 
      ${sessionStorage.getItem("lastName")}`;

      /* eslint-disable camelcase */
      const payload = {
        speciality: "", //passing empty string as speciality id since this is super admin account
        from_id: sessionStorage.getItem("userId"),
        session_id: chatSessionId,
        from_name: fromName,
        datatype: DATATYPES.notes,
        message: `${languageConstant[langKey].dr} ${selectedProviderName} 
        ${languageConstant[langKey].hasBeenRemovedFromTheChatBy} ${fromName}`,
        link: "",
        img_url: "",
      };
      /* eslint-enable camelcase */

      const data = await addChatData(payload);

      return data?.status === OK;
    } catch (error) {
      return false;
      // empty catch block since error is already handled in interceptors
    }
  };

  return (
    <Modal
      title={languageConstant[langKey].removeResponderProvider}
      centered
      open={visible}
      footer={
        !isError && (
          <div className="global-footer-container">
            <Button type="primary" className="general-button-large" onClick={handleCancel}>
              {languageConstant[langKey].cancel}
            </Button>
            <Button
              type="primary"
              className="general-button-large"
              onClick={() => {
                removeUserFromSession();
              }}
              disabled={loading}
            >
              {languageConstant[langKey].remove}
            </Button>
          </div>
        )
      }
      width={800}
      onCancel={handleCancel}
    >
      <hr />
      {loading ? (
        <div className={styles["chats-remove-responder-modal-container"]}>
          <div className={styles["chats-remove-responder-shimmer-container"]}>
            <Skeleton.Input
              active
              style={{
                position: "absolute",
                width: "100%",
                height: "40px",
              }}
            />
          </div>
        </div>
      ) : isError ? (
        <PlainSomethingWentWrong refetch={getSessionMembersDetailsFunction} />
      ) : newResponderNotAddedFlag ? (
        <div className={styles["chats-remove-responder-modal-container"]}>
          <Typography className={styles["new-providers-not-added"]}>
            {languageConstant[langKey].newProvidersNotAdded}
          </Typography>
        </div>
      ) : (
        <div className={styles["chats-remove-responder-modal-container"]}>
          <Select
            className={styles["chats-remove-responder-select"]}
            variant="filled"
            showSearch
            optionFilterProp="children"
            placeholder={languageConstant[langKey].selectProvider}
            onChange={(e) => {
              setSelectedUserId(e);
            }}
          >
            {sessionMembersDetail
              .sort((a, b) => a.first_name.localeCompare(b.first_name))
              .map((item) => (
                <Select.Option key={item.user_id} value={item.user_id}>
                  {item.first_name}
                  {item.last_name}
                </Select.Option>
              ))}
          </Select>
        </div>
      )}
    </Modal>
  );
}
